export const IMGURL =
  "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_508,h_320,c_fill/";

export const RESTUARANT_MENU =
  "https://www.swiggy.com/dapi/menu/pl?page-type=REGULAR_MENU&complete-menu=true&lat=13.0274175&lng=77.65884609999999&restaurantId=";

export const HOME_URL =
  "https://www.swiggy.com/dapi/restaurants/list/v5?lat=13.0274175&lng=77.65884609999999&page_type=DESKTOP_WEB_LISTING";

export const HOME_URL_RAW_DATA = {
  statusCode: 0,
  data: {
    cacheExpiryTime: 320,
    pages: 1,
    pageIndex: 0,
    scrubber: 0,
    filters: [
      {
        type: "FilterWidget",
        title: "Cuisines",
        key: "CUISINES",
        configType: "checkbox",
        visible: 1,
        options: [
          {
            option: "American",
            selected: 0,
            visible: 1,
          },
          {
            option: "Andhra",
            selected: 0,
            visible: 1,
          },
          {
            option: "Arabian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Asian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Awadhi",
            selected: 0,
            visible: 1,
          },
          {
            option: "Bakery",
            selected: 0,
            visible: 1,
          },
          {
            option: "Barbecue",
            selected: 0,
            visible: 1,
          },
          {
            option: "Bengali",
            selected: 0,
            visible: 1,
          },
          {
            option: "Beverages",
            selected: 0,
            visible: 1,
          },
          {
            option: "Bihari",
            selected: 0,
            visible: 1,
          },
          {
            option: "Biryani",
            selected: 0,
            visible: 1,
          },
          {
            option: "British",
            selected: 0,
            visible: 1,
          },
          {
            option: "Burgers",
            selected: 0,
            visible: 1,
          },
          {
            option: "Burmese",
            selected: 0,
            visible: 1,
          },
          {
            option: "Cafe",
            selected: 0,
            visible: 1,
          },
          {
            option: "Chaat",
            selected: 0,
            visible: 1,
          },
          {
            option: "Chettinad",
            selected: 0,
            visible: 1,
          },
          {
            option: "Chinese",
            selected: 0,
            visible: 1,
          },
          {
            option: "Coastal",
            selected: 0,
            visible: 1,
          },
          {
            option: "Combo",
            selected: 0,
            visible: 1,
          },
          {
            option: "Continental",
            selected: 0,
            visible: 1,
          },
          {
            option: "Desserts",
            selected: 0,
            visible: 1,
          },
          {
            option: "European",
            selected: 0,
            visible: 1,
          },
          {
            option: "Fast Food",
            selected: 0,
            visible: 1,
          },
          {
            option: "French",
            selected: 0,
            visible: 1,
          },
          {
            option: "Grill",
            selected: 0,
            visible: 1,
          },
          {
            option: "Gujarati",
            selected: 0,
            visible: 1,
          },
          {
            option: "Haleem",
            selected: 0,
            visible: 1,
          },
          {
            option: "Healthy Food",
            selected: 0,
            visible: 1,
          },
          {
            option: "Home Food",
            selected: 0,
            visible: 1,
          },
          {
            option: "Hyderabadi",
            selected: 0,
            visible: 1,
          },
          {
            option: "Ice Cream",
            selected: 0,
            visible: 1,
          },
          {
            option: "Ice Cream Cakes",
            selected: 0,
            visible: 1,
          },
          {
            option: "Indian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Indonesian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Italian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Italian-American",
            selected: 0,
            visible: 1,
          },
          {
            option: "Jain",
            selected: 0,
            visible: 1,
          },
          {
            option: "Japanese",
            selected: 0,
            visible: 1,
          },
          {
            option: "Juices",
            selected: 0,
            visible: 1,
          },
          {
            option: "Kebabs",
            selected: 0,
            visible: 1,
          },
          {
            option: "Kerala",
            selected: 0,
            visible: 1,
          },
          {
            option: "Keto",
            selected: 0,
            visible: 1,
          },
          {
            option: "Konkan",
            selected: 0,
            visible: 1,
          },
          {
            option: "Korean",
            selected: 0,
            visible: 1,
          },
          {
            option: "Lebanese",
            selected: 0,
            visible: 1,
          },
          {
            option: "Lucknowi",
            selected: 0,
            visible: 1,
          },
          {
            option: "Maharashtrian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Malaysian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Mangalorean",
            selected: 0,
            visible: 1,
          },
          {
            option: "Meat",
            selected: 0,
            visible: 1,
          },
          {
            option: "Mediterranean",
            selected: 0,
            visible: 1,
          },
          {
            option: "Mexican",
            selected: 0,
            visible: 1,
          },
          {
            option: "Middle Eastern",
            selected: 0,
            visible: 1,
          },
          {
            option: "Mughlai",
            selected: 0,
            visible: 1,
          },
          {
            option: "Naga",
            selected: 0,
            visible: 1,
          },
          {
            option: "Nepalese",
            selected: 0,
            visible: 1,
          },
          {
            option: "North Eastern",
            selected: 0,
            visible: 1,
          },
          {
            option: "North Indian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Oriental",
            selected: 0,
            visible: 1,
          },
          {
            option: "Oriya",
            selected: 0,
            visible: 1,
          },
          {
            option: "Paan",
            selected: 0,
            visible: 1,
          },
          {
            option: "Pan-Asian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Pastas",
            selected: 0,
            visible: 1,
          },
          {
            option: "Persian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Pizzas",
            selected: 0,
            visible: 1,
          },
          {
            option: "Portuguese",
            selected: 0,
            visible: 1,
          },
          {
            option: "Punjabi",
            selected: 0,
            visible: 1,
          },
          {
            option: "Raja",
            selected: 0,
            visible: 1,
          },
          {
            option: "Rajasthani",
            selected: 0,
            visible: 1,
          },
          {
            option: "Salads",
            selected: 0,
            visible: 1,
          },
          {
            option: "Seafood",
            selected: 0,
            visible: 1,
          },
          {
            option: "Singaporean",
            selected: 0,
            visible: 1,
          },
          {
            option: "Snacks",
            selected: 0,
            visible: 1,
          },
          {
            option: "South Indian",
            selected: 0,
            visible: 1,
          },
          {
            option: "Spanish",
            selected: 0,
            visible: 1,
          },
          {
            option: "Steakhouse",
            selected: 0,
            visible: 1,
          },
          {
            option: "Street Food",
            selected: 0,
            visible: 1,
          },
          {
            option: "Sushi",
            selected: 0,
            visible: 1,
          },
          {
            option: "Sweets",
            selected: 0,
            visible: 1,
          },
          {
            option: "Tandoor",
            selected: 0,
            visible: 1,
          },
          {
            option: "Tex-Mex",
            selected: 0,
            visible: 1,
          },
          {
            option: "Thai",
            selected: 0,
            visible: 1,
          },
          {
            option: "Thalis",
            selected: 0,
            visible: 1,
          },
          {
            option: "Tibetan",
            selected: 0,
            visible: 1,
          },
          {
            option: "Turkish",
            selected: 0,
            visible: 1,
          },
          {
            option: "Vietnamese",
            selected: 0,
            visible: 1,
          },
          {
            option: "Waffle",
            selected: 0,
            visible: 1,
          },
        ],
        detail: "",
        heading: "Cuisines",
        applicable: 1,
        searchable: 1,
      },
      {
        type: "FilterWidget",
        title: "Show Restaurants With",
        key: "SHOW_RESTAURANTS_WITH",
        configType: "checkbox",
        visible: 1,
        options: [
          {
            option: "Pure Veg",
            selected: 0,
            visible: 1,
          },
        ],
        detail: "",
        heading: "Offers & More",
        applicable: 1,
        searchable: 0,
      },
    ],
    sorts: [
      {
        type: "SortWidget",
        key: "RELEVANCE",
        title: "Relevance",
        selected: 1,
        visible: 1,
        defaultSelection: true,
      },
      {
        type: "SortWidget",
        key: "DELIVERY_TIME",
        title: "Delivery Time",
        selected: 0,
        visible: 1,
        defaultSelection: false,
      },
      {
        type: "SortWidget",
        key: "RATING",
        title: "Rating",
        selected: 0,
        visible: 1,
        defaultSelection: false,
      },
      {
        type: "SortWidget",
        key: "COST_FOR_TWO",
        title: "Cost: Low to High",
        selected: 0,
        visible: 1,
        defaultSelection: false,
      },
      {
        type: "SortWidget",
        key: "COST_FOR_TWO_H2L",
        title: "Cost: High to Low",
        selected: 0,
        visible: 1,
        defaultSelection: false,
      },
    ],
    configs: {
      ribbons: {
        PREORDER: {
          type: "PREORDER",
          text: "Preorder",
          textColor: "#ffffff",
          imageId: "sfefefefeegeg",
          topBackgroundColor: "#d53d4c",
          bottomBackgroundColor: "#af2330",
          priority: 3,
        },
        EXCLUSIVE: {
          type: "EXCLUSIVE",
          text: "Exclusive",
          textColor: "#ffffff",
          imageId: "sfefefefeegeg",
          topBackgroundColor: "#fa4a5b",
          bottomBackgroundColor: "#d12a3b",
          priority: 2,
        },
        NEW: {
          type: "NEW",
          text: "Newly Added",
          textColor: "#ffffff",
          imageId: "sfefefefeegeg",
          topBackgroundColor: "#d53d4c",
          bottomBackgroundColor: "#af2330",
          priority: 4,
        },
        REPEAT: {
          type: "REPEAT",
          text: "Repeat",
          textColor: "#ffffff",
          imageId: "sfefefefeegeg",
          topBackgroundColor: "#D53D4C",
          bottomBackgroundColor: "#B02331",
          priority: 6,
        },
        CLOUD: {
          type: "CLOUD",
          text: "Daily Menus",
          textColor: "#ffffff",
          imageId: "sfefefefeegeg",
          topBackgroundColor: "#fa4a5b",
          bottomBackgroundColor: "#d12a3b",
          priority: 5,
        },
        PREMIUM: {
          type: "PREMIUM",
          text: "Premium",
          textColor: "#ffffff",
          imageId: "sfefefefeegeg",
          topBackgroundColor: "#8a584b",
          bottomBackgroundColor: "#583229",
          priority: 7,
        },
        PROMOTED: {
          type: "PROMOTED",
          text: "Promoted",
          textColor: "#ffffff",
          imageId: "sfefefefeegeg",
          topBackgroundColor: "#3a3c41",
          bottomBackgroundColor: "#1e2023",
          priority: 1,
        },
      },
      croutons: {
        RAIN: {
          type: "Crouton",
          bgColor: "#282C3F",
          icon: "surge_listing_piuzrv",
          textColor: "#ffffff",
          title: "Bad Weather",
          message: "${amount} extra levied on some restaurants",
        },
        RAIN_DEFAULT: {
          type: "Crouton",
          bgColor: "#282C3F",
          icon: "surge_listing_piuzrv",
          textColor: "#ffffff",
          title: "Bad Weather",
          message: "Your orders may be delayed by 5-10 mins",
        },
        SERVICEABLE_WITH_BANNER_RAIN: {
          type: "Crouton",
          bgColor: "#282C3F",
          icon: "rain_crouton_4x",
          textColor: "#ffffff",
          title: "Bad Weather",
          message: "Few restaurants unserviceable due to rains",
        },
        RAIN_LOW: {
          type: "Crouton",
          bgColor: "#282C3F",
          icon: "surge_listing_piuzrv",
          textColor: "#ffffff",
          title: "Bad Weather",
          message: "Your orders may be delayed by 5-10 mins",
        },
        RAIN_HIGH: {
          type: "Crouton",
          bgColor: "#282C3F",
          icon: "surge_listing_piuzrv",
          textColor: "#ffffff",
          title: "Bad Weather",
          message: "Your orders may be delayed by 5-10 mins",
        },
        SPECIAL: {
          type: "Crouton",
          bgColor: "#282C3F",
          icon: "surge_listing_piuzrv",
          textColor: "#ffffff",
          title: "High Demand",
          message: "Surge fee of Rs ${amount} may be applicable",
        },
      },
    },
    cards: [
      {
        cardType: "carousel",
        layoutAlignmentType: "VERTICAL",
        data: {
          type: "carousel",
          subtype: "topCarousel",
          data: {
            cards: [
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 635529,
                  creativeId: "rng/md/carousel/production/pneknawbadtvceqzwiep",
                  type: "restaurantCollectionV2",
                  link: "49301",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 619141,
                  creativeId: "rng/md/carousel/production/zpkkdkmvlj5cuvqbc50t",
                  type: "restaurantCollectionV2",
                  link: "56413",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 619143,
                  creativeId: "rng/md/carousel/production/lzkjv3sxwwjkzg0vxpvt",
                  type: "restaurantCollectionV2",
                  link: "56422",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 619142,
                  creativeId: "rng/md/carousel/production/tgnvusbs3fnzz7xupeno",
                  type: "restaurantCollectionV2",
                  link: "56417",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 619145,
                  creativeId: "rng/md/carousel/production/nhubtkw7dukg3ukcmpam",
                  type: "restaurantCollectionV2",
                  link: "56414",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 607193,
                  creativeId: "rng/md/carousel/production/s5ug2key6e2sptaxku5v",
                  type: "restaurantCollectionV2",
                  link: "56410",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 607188,
                  creativeId: "rng/md/carousel/production/oxe97jexxbnxqtbhg2zo",
                  type: "restaurantCollectionV2",
                  link: "11740",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 607195,
                  creativeId: "rng/md/carousel/production/ifi2lbzxeu1hvsqrsip3",
                  type: "restaurantCollectionV2",
                  link: "56421",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  thirdPartyVendor: "",
                  thirdPartyAddress: false,
                },
              },
            ],
          },
        },
        parentWidget: false,
      },
      {
        cardType: "carousel",
        layoutAlignmentType: "VERTICAL",
        data: {
          type: "carousel",
          subtype: "openFilter",
          data: {
            cards: [
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 181684,
                  creativeId: "bkhjfipzydkoqncrxpyi",
                  type: "restaurantCollectionV2",
                  link: "11718",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  title: "Offers Near You",
                  subtitle: "0 OPTIONS",
                  restaurants: [],
                  totalCount: 0,
                  dwebOpenFilterTitle: "Offers Near You",
                  dwebOpenFilterBgColor: "#FF0000",
                  dwebOpenFilterSelectIcon: "eyperwsfhaoa5vktjtu4",
                  dwebOpenFilterDeselectIcon: "urw8usdf4mk9aywd59gq",
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 273242,
                  creativeId: "rng/md/carousel/production/vquntqrzvkb08pe59cec",
                  type: "restaurantCollectionV2",
                  link: "46147",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  title: "Top Picks",
                  subtitle: "0 OPTIONS",
                  restaurants: [],
                  totalCount: 0,
                  dwebOpenFilterTitle: "Top Picks",
                  dwebOpenFilterBgColor: "#FF0000",
                  dwebOpenFilterSelectIcon:
                    "rng/md/carousel/production/vt13uzhjrg5r49kh9oru",
                  dwebOpenFilterDeselectIcon:
                    "rng/md/carousel/production/ser2sttildk9yt7t2s91",
                },
              },
              {
                type: "carouselElement",
                subtype: "image",
                data: {
                  bannerId: 181680,
                  creativeId: "a3jqjjod5taken7dh1bs",
                  type: "restaurantCollectionV2",
                  link: "11721",
                  cityId: 0,
                  restaurantUuid: "",
                  restaurantParentId: "0",
                  title: "Veg Only",
                  subtitle: "0 OPTIONS",
                  restaurants: [],
                  totalCount: 0,
                  dwebOpenFilterTitle: "Vegetarian Options",
                  dwebOpenFilterBgColor: "#FF0000",
                  dwebOpenFilterSelectIcon: "qtjc8dzfexg72lug37a0",
                  dwebOpenFilterDeselectIcon: "bm8bziikwyvwqsml1clm",
                },
              },
            ],
          },
        },
        parentWidget: false,
      },
      {
        cardType: "seeAllRestaurants",
        layoutAlignmentType: "VERTICAL",
        data: {
          type: "seeAllRestaurants",
          data: {
            title: "SEE ALL",
            totalOpenRestaurants: 1531,
            cards: [
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "356251",
                  name: "Hotel Empire",
                  uuid: "ce6a2ab4-8100-429b-902c-5f2c9fb933ce",
                  city: "1",
                  area: "Kothanur",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "emd7fqyffultlfisgw4w",
                  cuisines: ["North Indian", "Kebabs", "Biryani"],
                  tags: [],
                  costForTwo: 30000,
                  costForTwoString: "₹300 FOR TWO",
                  deliveryTime: 28,
                  minDeliveryTime: 28,
                  maxDeliveryTime: 28,
                  slaString: "28 MINS",
                  lastMileTravel: 5.699999809265137,
                  slugs: {
                    restaurant: "hotel-empire-geddalahalli-geddalahalli",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "sy no 8/1, Ground Floor, Kothanur Village, Kr Puram Hobli, Bangalore , Kr Puram BBMP east- Karnataka 560077",
                  locality: "",
                  parentId: 475,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  ribbon: [
                    {
                      type: "PROMOTED",
                    },
                  ],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 5000,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 5000,
                    message: "",
                    title: "Delivery Charge",
                    amount: "5000",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID:
                    "cid=7563204~p=1~eid=00000189-915f-d96a-05f1-fdbf0092016b~srvts=1690361125226",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "5.6 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "10% OFF",
                    subHeader: "UPTO ₹40",
                    discountTag: "",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "356251",
                    deliveryTime: 28,
                    minDeliveryTime: 28,
                    maxDeliveryTime: 28,
                    lastMileTravel: 5.699999809265137,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: true,
                  avgRating: "4.0",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "90018",
                  name: "Meghana Foods",
                  uuid: "0767b105-9e87-48ba-b142-b22a47116c76",
                  city: "1",
                  area: "Kalyan Nagar",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "fsqyubnn1p1mo57ek4bi",
                  cuisines: [
                    "Biryani",
                    "Andhra",
                    "South Indian",
                    "North Indian",
                    "Chinese",
                    "Seafood",
                  ],
                  tags: [],
                  costForTwo: 50000,
                  costForTwoString: "₹500 FOR TWO",
                  deliveryTime: 31,
                  minDeliveryTime: 31,
                  maxDeliveryTime: 31,
                  slaString: "31 MINS",
                  lastMileTravel: 3,
                  slugs: {
                    restaurant: "meghana-foods-electronic-city",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "NO. 524, HK ARCADE, HRBR LAYOUT, 2 ND BLOCK, 5 TH MAIN, RINGROAD, KALYAN NAGAR, BANGALORE-560043",
                  locality: "HRBR Layout",
                  parentId: 635,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 3500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 3500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "3500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "3 kms",
                  hasSurge: false,
                  sla: {
                    restaurantId: "90018",
                    deliveryTime: 31,
                    minDeliveryTime: 31,
                    maxDeliveryTime: 31,
                    lastMileTravel: 3,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.3",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "240552",
                  name: "Udupi Gardenia (jayanthinagar)",
                  uuid: "45f8080f-6a71-4a78-94b1-79768e2a5cfa",
                  city: "1",
                  area: "Ramamurthy nagar",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "lfrcp0wsmohpowae3k26",
                  cuisines: [
                    "South Indian",
                    "North Indian",
                    "Chinese",
                    "Juices",
                    "Ice Cream",
                    "Chaat",
                  ],
                  tags: [],
                  costForTwo: 25000,
                  costForTwoString: "₹250 FOR TWO",
                  deliveryTime: 17,
                  minDeliveryTime: 17,
                  maxDeliveryTime: 17,
                  slaString: "17 MINS",
                  lastMileTravel: 0.699999988079071,
                  slugs: {
                    restaurant:
                      "udupi-gardenia-ramamurthy-nagar-ramamurthy-nagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "No 135, Horamavu village, Jayanthinagara, Horamavu, 560043",
                  locality: "Jayanthi Nagar",
                  parentId: 20144,
                  unserviceable: false,
                  veg: true,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  ribbon: [
                    {
                      type: "PROMOTED",
                    },
                  ],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 2500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 2500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "2500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID:
                    "cid=7587681~p=4~eid=00000189-915f-d96a-05f1-fdc00092043f~srvts=1690361125226",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "0.6 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "₹100 OFF",
                    subHeader: "ABOVE ₹299",
                    discountTag: "FLAT DEAL",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "240552",
                    deliveryTime: 17,
                    minDeliveryTime: 17,
                    maxDeliveryTime: 17,
                    lastMileTravel: 0.699999988079071,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: true,
                  avgRating: "4.2",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "70161",
                  name: "Imperio Restaurant",
                  uuid: "f129501a-ff9f-4a61-b6de-a6b7932e7972",
                  city: "1",
                  area: "Hrbr Layout",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "a1629b9c7f59577a55411e94425c6d99",
                  cuisines: [
                    "Chinese",
                    "South Indian",
                    "North Indian",
                    "Continental",
                    "Desserts",
                    "Kerala",
                    "Andhra",
                    "Beverages",
                    "Mughlai",
                    "Seafood",
                    "Hyderabadi",
                    "Healthy Food",
                    "Arabian",
                    "Biryani",
                    "Burgers",
                    "Barbecue",
                    "Oriental",
                  ],
                  tags: [],
                  costForTwo: 30000,
                  costForTwoString: "₹300 FOR TWO",
                  deliveryTime: 28,
                  minDeliveryTime: 28,
                  maxDeliveryTime: 28,
                  slaString: "28 MINS",
                  lastMileTravel: 2.799999952316284,
                  slugs: {
                    restaurant:
                      "imperio-restaurant-kalyan-nagar-kammanahallikalyan-nagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address: "No.429 1st block hrbr layout kalyan Nagar",
                  locality: "1st Block",
                  parentId: 5610,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 2500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 2500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "2500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "2.7 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "50% OFF",
                    subHeader: "UPTO ₹100",
                    discountTag: "",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "70161",
                    deliveryTime: 28,
                    minDeliveryTime: 28,
                    maxDeliveryTime: 28,
                    lastMileTravel: 2.799999952316284,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "3.8",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "167668",
                  name: "Easy Bites by Hotel Empire",
                  uuid: "550df3cf-0d12-4348-b411-5d81e5514f93",
                  city: "1",
                  area: "Kammanahalli/Kalyan Nagar",
                  totalRatingsString: "1000+ ratings",
                  cloudinaryImageId: "d06b7fa84cb8f8193f60d4c2de172347",
                  cuisines: ["Snacks", "Fast Food", "Beverages"],
                  tags: [],
                  costForTwo: 20000,
                  costForTwoString: "₹200 FOR TWO",
                  deliveryTime: 37,
                  minDeliveryTime: 37,
                  maxDeliveryTime: 37,
                  slaString: "37 MINS",
                  lastMileTravel: 3.9000000953674316,
                  slugs: {
                    restaurant: "easy-bites-kammanahallikalyan-nagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "83, Nehru Main Road, Kammanahalli, Bangalore  560 084",
                  locality: "Kacharakanahalli",
                  parentId: 8503,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  ribbon: [
                    {
                      type: "PROMOTED",
                    },
                  ],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 3500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 3500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "3500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID:
                    "cid=7563229~p=7~eid=00000189-915f-d96a-05f1-fdc10092075e~srvts=1690361125226",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "3.9 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "10% OFF",
                    subHeader: "UPTO ₹40",
                    discountTag: "",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "167668",
                    deliveryTime: 37,
                    minDeliveryTime: 37,
                    maxDeliveryTime: 37,
                    lastMileTravel: 3.9000000953674316,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: true,
                  avgRating: "4.0",
                  totalRatings: 1000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "132930",
                  name: "Sangam Mess",
                  uuid: "7923a991-ca40-4b4c-9f3f-eb39edf196df",
                  city: "1",
                  area: "Kammanahalli/Kalyan Nagar",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "it1t0ccxhlko4a1bpjg0",
                  cuisines: [
                    "Kerala",
                    "South Indian",
                    "Biryani",
                    "Seafood",
                    "Beverages",
                  ],
                  tags: [],
                  costForTwo: 30000,
                  costForTwoString: "₹300 FOR TWO",
                  deliveryTime: 30,
                  minDeliveryTime: 30,
                  maxDeliveryTime: 30,
                  slaString: "30 MINS",
                  lastMileTravel: 3.5999999046325684,
                  slugs: {
                    restaurant:
                      "sangam-mess-kammanahallikalyan-nagar-kammanahallikalyan-nagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "7 3rd Cross Chinnappa Layot Opp Sansar Collections Kammanahalli Main Road Bangalore 560084",
                  locality: "Chinnappa Layout",
                  parentId: 20972,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 3500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 3500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "3500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "3.5 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "FREE ITEM",
                    subHeader: "",
                    discountTag: "",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "132930",
                    deliveryTime: 30,
                    minDeliveryTime: 30,
                    maxDeliveryTime: 30,
                    lastMileTravel: 3.5999999046325684,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.3",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "573392",
                  name: "Burger King",
                  uuid: "978bfd80-9c56-486c-8eb0-f27e95cc30e1",
                  city: "1",
                  area: "Ramamurthy nagar",
                  totalRatingsString: "1000+ ratings",
                  cloudinaryImageId: "e33e1d3ba7d6b2bb0d45e1001b731fcf",
                  cuisines: ["American"],
                  tags: [],
                  costForTwo: 35000,
                  costForTwoString: "₹350 FOR TWO",
                  deliveryTime: 24,
                  minDeliveryTime: 24,
                  maxDeliveryTime: 24,
                  slaString: "24 MINS",
                  lastMileTravel: 3,
                  slugs: {
                    restaurant: "burger-king-tc-palya-ramamurthy-nagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "Ground & First Floor, No 14, 11th main, TC Palya Main road, Akshay Nagar, Aneppa Circle, Rammurthy Nagar, Bangalore-560016",
                  locality: "TC Palya",
                  parentId: 166,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 3500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 3500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "3500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "3 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "60% OFF",
                    subHeader: "UPTO ₹120",
                    discountTag: "",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "573392",
                    deliveryTime: 24,
                    minDeliveryTime: 24,
                    maxDeliveryTime: 24,
                    lastMileTravel: 3,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.0",
                  totalRatings: 1000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "44239",
                  name: "Mani's Dum Biryani",
                  uuid: "4193cc99-3561-4aef-9ce0-ceae6b45c7b2",
                  city: "1",
                  area: "Kalyan Nagar",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "xgc7zrdnmeitmzlvqwey",
                  cuisines: ["Biryani"],
                  tags: [],
                  costForTwo: 49900,
                  costForTwoString: "₹499 FOR TWO",
                  deliveryTime: 25,
                  minDeliveryTime: 25,
                  maxDeliveryTime: 25,
                  slaString: "25 MINS",
                  lastMileTravel: 3,
                  slugs: {
                    restaurant:
                      "maniss-dum-biryani-kammanahalli-kammanahallikalyan-nagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "418, 5A Cross, 2nd Stage, near Girias, HRBR Layout, Kalyan Nagar, Bengaluru, Karnataka 560043",
                  locality: "HRBR Layout",
                  parentId: 623,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 3500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 3500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "3500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "3 kms",
                  hasSurge: false,
                  sla: {
                    restaurantId: "44239",
                    deliveryTime: 25,
                    minDeliveryTime: 25,
                    maxDeliveryTime: 25,
                    lastMileTravel: 3,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.3",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "180551",
                  name: "Razakhiya Hotel",
                  uuid: "12dc78b2-6423-4117-afcc-c26cd00339e6",
                  city: "1",
                  area: "Hbr Layout",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "gpmwhnujwdsb4qciw1wt",
                  cuisines: ["North Indian", "Indian"],
                  tags: [],
                  costForTwo: 30000,
                  costForTwoString: "₹300 FOR TWO",
                  deliveryTime: 35,
                  minDeliveryTime: 35,
                  maxDeliveryTime: 35,
                  slaString: "35 MINS",
                  lastMileTravel: 5.5,
                  slugs: {
                    restaurant:
                      "razakhiya-hotel-nagavara-hennur-nagavara-hennur",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "80 feet Road, HBR Layout, Hebbala, Bangalore - 560045",
                  locality: "80 feet Road",
                  parentId: 21645,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  ribbon: [
                    {
                      type: "PROMOTED",
                    },
                  ],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 5000,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 5000,
                    message: "",
                    title: "Delivery Charge",
                    amount: "5000",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID:
                    "cid=7588233~p=13~eid=00000189-915f-d96a-05f1-fdc300920d35~srvts=1690361125226",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "5.5 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "30% OFF",
                    subHeader: "UPTO ₹75",
                    discountTag: "",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "180551",
                    deliveryTime: 35,
                    minDeliveryTime: 35,
                    maxDeliveryTime: 35,
                    lastMileTravel: 5.5,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: true,
                  avgRating: "4.1",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "52934",
                  name: "Udupi Gardenia",
                  uuid: "523e8749-942c-46ea-b087-e8e9db3818cf",
                  city: "1",
                  area: "Banasawadi",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "hm9alpsl6gvphjgfyyxc",
                  cuisines: [
                    "South Indian",
                    "North Indian",
                    "Desserts",
                    "Chinese",
                    "Chaat",
                    "Ice Cream",
                    "Juices",
                  ],
                  tags: [],
                  costForTwo: 20000,
                  costForTwoString: "₹200 FOR TWO",
                  deliveryTime: 16,
                  minDeliveryTime: 16,
                  maxDeliveryTime: 16,
                  slaString: "16 MINS",
                  lastMileTravel: 0.699999988079071,
                  slugs: {
                    restaurant: "udupi-gardenia-banaswadi-banaswadi",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "#145/1 , A Munireddy layout , 1st cross , Horamavu Main road , Banaswadi , Blore-43",
                  locality: "Munireddy layout",
                  parentId: 219016,
                  unserviceable: false,
                  veg: true,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 2500,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 2500,
                    message: "",
                    title: "Delivery Charge",
                    amount: "2500",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "0.6 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "10% OFF",
                    subHeader: "ABOVE ₹700",
                    discountTag: "FLAT DEAL",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "52934",
                    deliveryTime: 16,
                    minDeliveryTime: 16,
                    maxDeliveryTime: 16,
                    lastMileTravel: 0.699999988079071,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.4",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "486527",
                  name: "Nandhana Palace",
                  uuid: "a56a3880-f5e6-4f0a-88aa-63f2bf9602fc",
                  city: "1",
                  area: "Hennur Main Road",
                  totalRatingsString: "5000+ ratings",
                  cloudinaryImageId: "195876a3181ef63f76e45e3a7b49b585",
                  cuisines: ["Andhra"],
                  tags: [],
                  costForTwo: 50000,
                  costForTwoString: "₹500 FOR TWO",
                  deliveryTime: 30,
                  minDeliveryTime: 30,
                  maxDeliveryTime: 30,
                  slaString: "30 MINS",
                  lastMileTravel: 6.900000095367432,
                  slugs: {
                    restaurant: "nandhana-palace-geddalahalli-geddalahalli",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "KGS Arcade  Khatha No 328 1  228 227 13 14  BBMP Sl No 1  13   14 Ward No 54  Byrathi Kothanur  Hennur Main Road  Bangalore   560 077",
                  locality: "Geddalahalli",
                  parentId: 2120,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  ribbon: [
                    {
                      type: "PROMOTED",
                    },
                  ],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 5800,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 5800,
                    message: "",
                    title: "Delivery Charge",
                    amount: "5800",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID:
                    "cid=7556709~p=19~eid=00000189-915f-d96a-05f1-fdc50092130a~srvts=1690361125226",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "6.9 kms",
                  hasSurge: false,
                  sla: {
                    restaurantId: "486527",
                    deliveryTime: 30,
                    minDeliveryTime: 30,
                    maxDeliveryTime: 30,
                    lastMileTravel: 6.900000095367432,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: true,
                  avgRating: "4.2",
                  totalRatings: 5000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "70004",
                  name: "Sangam Sweets",
                  uuid: "b999f9f8-72f7-421f-afc0-489e8030db87",
                  city: "1",
                  area: "Hennur",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "zajncsgvytdtlgdbffi8",
                  cuisines: ["North Indian", "Desserts"],
                  tags: [],
                  costForTwo: 10000,
                  costForTwoString: "₹100 FOR TWO",
                  deliveryTime: 30,
                  minDeliveryTime: 30,
                  maxDeliveryTime: 30,
                  slaString: "30 MINS",
                  lastMileTravel: 6.699999809265137,
                  slugs: {
                    restaurant: "sangam-sweets-hennur-nagavara-hennur",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "41/42, Hennur Bagalur Rd, Sangam Enclave, Kuvempu Layout, Kothanur, Bengaluru, Karnataka 560077",
                  locality: "Kothanur",
                  parentId: 805,
                  unserviceable: false,
                  veg: true,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  ribbon: [
                    {
                      type: "PROMOTED",
                    },
                  ],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 5800,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 5800,
                    message: "",
                    title: "Delivery Charge",
                    amount: "5800",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID:
                    "cid=7558635~p=16~eid=00000189-915f-d96a-05f1-fdc40092106c~srvts=1690361125226",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "6.6 kms",
                  hasSurge: false,
                  sla: {
                    restaurantId: "70004",
                    deliveryTime: 30,
                    minDeliveryTime: 30,
                    maxDeliveryTime: 30,
                    lastMileTravel: 6.699999809265137,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: true,
                  avgRating: "4.4",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "34301",
                  name: "Sri Udupi Park (100ft Road)",
                  uuid: "82e5a42b-6a2d-45c7-a096-320333bd6c4e",
                  city: "1",
                  area: "Indiranagar",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "jxp8y1chnqljwqylpkov",
                  cuisines: [
                    "South Indian",
                    "North Indian",
                    "Chaat",
                    "Beverages",
                    "Chinese",
                  ],
                  tags: [],
                  costForTwo: 10000,
                  costForTwoString: "₹100 FOR TWO",
                  deliveryTime: 49,
                  minDeliveryTime: 49,
                  maxDeliveryTime: 49,
                  slaString: "49 MINS",
                  lastMileTravel: 8.399999618530273,
                  slugs: {
                    restaurant: "sri-udupi-park-indiranagar-indiranagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "#273 100 FEET ROAD DIFFENCE COLONY 6TH MAIN INDIRANAGAR BANGALORE-38",
                  locality: "Defence Colony",
                  parentId: 194697,
                  unserviceable: false,
                  veg: true,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 8100,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 8100,
                    message: "",
                    title: "Delivery Charge",
                    amount: "8100",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 1,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "8.3 kms",
                  hasSurge: false,
                  sla: {
                    restaurantId: "34301",
                    deliveryTime: 49,
                    minDeliveryTime: 49,
                    maxDeliveryTime: 49,
                    lastMileTravel: 8.399999618530273,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "IT_IS_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.3",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "115005",
                  name: "Udupi vaibhav",
                  uuid: "d3cec3be-7038-4562-a76d-3f6bdfcf3020",
                  city: "1",
                  area: "Hennur",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "mj1txffjfnjtjmx7ddki",
                  cuisines: ["Indian"],
                  tags: [],
                  costForTwo: 18000,
                  costForTwoString: "₹180 FOR TWO",
                  deliveryTime: 28,
                  minDeliveryTime: 28,
                  maxDeliveryTime: 28,
                  slaString: "28 MINS",
                  lastMileTravel: 6,
                  slugs: {
                    restaurant: "udupi-vaibhav-na-marathahalli",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address:
                    "NO 50/8B GUBBY CROSS BANGALORE KOTHANPUR VILLAGE BANGALORE KR PURAM",
                  locality: "Kothanur",
                  parentId: 21621,
                  unserviceable: false,
                  veg: true,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 5800,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 5800,
                    message: "",
                    title: "Delivery Charge",
                    amount: "5800",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 0,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "6 kms",
                  hasSurge: false,
                  aggregatedDiscountInfoV3: {
                    header: "₹100 OFF",
                    subHeader: "ABOVE ₹299",
                    discountTag: "FLAT DEAL",
                    headerTypeV2: 0,
                  },
                  sla: {
                    restaurantId: "115005",
                    deliveryTime: 28,
                    minDeliveryTime: 28,
                    maxDeliveryTime: 28,
                    lastMileTravel: 6,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "NOT_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.2",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
              {
                type: "restaurant",
                data: {
                  type: "F",
                  id: "41101",
                  name: "Nagarjuna",
                  uuid: "68af3f89-da9a-4ad7-85aa-c108a1331719",
                  city: "1",
                  area: "Indiranagar",
                  totalRatingsString: "10000+ ratings",
                  cloudinaryImageId: "yhxc0v2zi1u47finne1k",
                  cuisines: [
                    "Andhra",
                    "South Indian",
                    "Biryani",
                    "Beverages",
                    "Desserts",
                  ],
                  tags: [],
                  costForTwo: 60000,
                  costForTwoString: "₹600 FOR TWO",
                  deliveryTime: 48,
                  minDeliveryTime: 48,
                  maxDeliveryTime: 48,
                  slaString: "48 MINS",
                  lastMileTravel: 8.399999618530273,
                  slugs: {
                    restaurant: "nagarjuna-indiranagar-indiranagar",
                    city: "bangalore",
                  },
                  cityState: "1",
                  address: "#195, Double road, Indiranagar, Bangalore  560038",
                  locality: "Double Road",
                  parentId: 4168,
                  unserviceable: false,
                  veg: false,
                  select: false,
                  favorite: false,
                  tradeCampaignHeaders: [],
                  chain: [],
                  feeDetails: {
                    fees: [
                      {
                        name: "distance",
                        fee: 8100,
                        message: "",
                      },
                      {
                        name: "time",
                        fee: 0,
                        message: "",
                      },
                      {
                        name: "special",
                        fee: 0,
                        message: "",
                      },
                    ],
                    totalFees: 8100,
                    message: "",
                    title: "Delivery Charge",
                    amount: "8100",
                    icon: "",
                  },
                  availability: {
                    opened: true,
                    nextOpenMessage: "",
                    nextCloseMessage: "",
                  },
                  longDistanceEnabled: 1,
                  rainMode: "NONE",
                  thirdPartyAddress: false,
                  thirdPartyVendor: "",
                  adTrackingID: "",
                  badges: {
                    imageBased: [],
                    textBased: [],
                    textExtendedBadges: [],
                  },
                  lastMileTravelString: "8.3 kms",
                  hasSurge: false,
                  sla: {
                    restaurantId: "41101",
                    deliveryTime: 48,
                    minDeliveryTime: 48,
                    maxDeliveryTime: 48,
                    lastMileTravel: 8.399999618530273,
                    lastMileDistance: 0,
                    serviceability: "SERVICEABLE",
                    rainMode: "NONE",
                    longDistance: "IT_IS_LONG_DISTANCE",
                    preferentialService: false,
                    iconType: "EMPTY",
                  },
                  promoted: false,
                  avgRating: "4.4",
                  totalRatings: 10000,
                  new: false,
                },
                subtype: "basic",
              },
            ],
            totalRestaurants: 1531,
          },
          subtype: "personalized",
        },
        parentWidget: false,
      },
    ],
    nextPageFetch: 0,
  },
  tid: "61a095a6-e016-49b0-980e-94532ae54c9d",
  sid: "8ee62a84-ce7e-43aa-affe-21ae0792f928",
  deviceId: "4c76eb89-8b7e-d2b8-31f7-514fe5a48652",
  csrfToken: "er7LlpriBIeL-RkXSsqpyL-h1ThGVrg--nuogF6o",
};

export const RESTUARANT_MENU_RAW_DATA = {
  statusCode: 0,
  data: {
    statusMessage: "done successfully",
    cards: [
      {
        card: {
          card: {
            "@type":
              "type.googleapis.com/swiggy.presentation.food.v2.Restaurant",
            info: {
              id: "356251",
              name: "Hotel Empire",
              city: "Bangalore",
              slugs: {
                restaurant: "hotel-empire-geddalahalli-geddalahalli",
                city: "bangalore",
              },
              uniqueId: "ce6a2ab4-8100-429b-902c-5f2c9fb933ce",
              cloudinaryImageId: "emd7fqyffultlfisgw4w",
              areaName: "Kothanur",
              costForTwo: "30000",
              costForTwoMessage: "₹300 for two",
              cuisines: ["North Indian", "Kebabs"],
              avgRating: 4,
              feeDetails: {
                restaurantId: "356251",
                fees: [
                  {
                    name: "distance",
                    fee: 5000,
                  },
                  {
                    name: "time",
                  },
                  {
                    name: "special",
                  },
                ],
                totalFee: 5000,
                title: "Delivery Charge",
                amount: "5000",
              },
              parentId: "475",
              avgRatingString: "4.0",
              totalRatingsString: "10K+ ratings",
              sla: {
                restaurantId: "356251",
                deliveryTime: 28,
                minDeliveryTime: 28,
                maxDeliveryTime: 28,
                lastMileTravel: 5.7,
                serviceability: "SERVICEABLE",
                stressFactor: 0.62196964,
                rainMode: "RAIN_MODE_NONE",
                longDistance: "LONG_DISTANCE_NOT_LONG_DISTANCE",
                zoneId: 393,
                slaString: "28 MINS",
                lastMileTravelString: "5.6 km",
                iconType: "ICON_TYPE_EMPTY",
              },
              availability: {
                nextCloseTime: "2023-07-27 01:00:00",
                visibility: true,
                opened: true,
                restaurantClosedMeta: {},
              },
              aggregatedDiscountInfo: {
                header: "10% off",
                shortDescriptionList: [
                  {
                    meta: "10% off | Use TRYNEW",
                    discountType: "Percentage",
                    operationType: "RESTAURANT",
                  },
                  {
                    meta: "Free Tender Coconut Pudding on orders above ₹499",
                    discountType: "Freebie",
                    operationType: "RESTAURANT",
                  },
                ],
                descriptionList: [
                  {
                    meta: "10% off up to ₹40 | Use code TRYNEW",
                    discountType: "Percentage",
                    operationType: "RESTAURANT",
                  },
                  {
                    meta: "Free Tender Coconut Pudding on orders above ₹499",
                    discountType: "Freebie",
                    operationType: "RESTAURANT",
                  },
                ],
                visible: true,
              },
              badges: {},
              slugString: "hotel-empire-geddalahalli-geddalahalli",
              multiOutlet: true,
              isOpen: true,
              labels: [
                {
                  title: "Timings",
                  message: "null",
                },
                {
                  title: "Address",
                  message:
                    "sy no 8/1, Ground Floor, Kothanur Village, Kr Puram Hobli, Bangalore , Kr Puram BBMP east- Karnataka 560077",
                },
                {
                  title: "Cuisines",
                  message: "North Indian,Kebabs",
                },
              ],
              totalRatings: 10000,
              aggregatedDiscountInfoV2: {
                header: "10% off",
                shortDescriptionList: [
                  {
                    meta: "10% off | Use TRYNEW",
                    discountType: "Percentage",
                    operationType: "RESTAURANT",
                  },
                  {
                    meta: "Free Tender Coconut Pudding on orders above ₹499",
                    discountType: "Freebie",
                    operationType: "RESTAURANT",
                  },
                ],
                descriptionList: [
                  {
                    meta: "10% off up to ₹40 | Use code TRYNEW",
                    discountType: "Percentage",
                    operationType: "RESTAURANT",
                  },
                  {
                    meta: "Free Tender Coconut Pudding on orders above ₹499",
                    discountType: "Freebie",
                    operationType: "RESTAURANT",
                  },
                ],
                couponDetailsCta: "View coupon details",
              },
              type: "F",
              nudgeBanners: [
                {
                  minValue: 350,
                  maxValue: 500,
                  priority: 3,
                  couponCode: "PARTY",
                  discountInfo: {
                    discountType: "Percentage",
                    value: 10,
                  },
                  lockedMessage:
                    "Add items worth ₹<amount> to unlock flat 10% off | Code PARTY",
                  unlockedMessage:
                    "PARTY Coupon Unlocked! Use it to save flat 10% off",
                  logoCtx: {},
                },
              ],
              headerBanner: {
                url: "swiggy://webview?is_external=false&webview_url=https://www.swiggy.com/restaurant-info/356251",
              },
              expectationNotifiers: [
                {
                  icon: {},
                  popup: {
                    cta: {},
                  },
                  type: "DISTANCE_FEE_NON_FOOD_LM",
                  halfCardPopup: {
                    halfCardPopupHeader: {},
                  },
                },
              ],
              generalPurposeInfoListV2: [
                {
                  cta: {
                    info: {
                      recordings: {},
                    },
                    linkCta: {},
                  },
                },
              ],
              ratingSlab: "RATING_SLAB_4",
              orderabilityCommunication: {
                title: {},
                subTitle: {},
                message: {},
                customIcon: {},
              },
              hasBestsellerItems: true,
              cartOrderabilityNudgeBanner: {
                parameters: {},
                presentation: {},
              },
              areaPostalCode: "0",
              latLong: "13.05797,77.6484",
            },
            analytics: {},
          },
          relevance: {
            type: "RELEVANCE_TYPE_CHECK_ORDERABILITY_ON_ITEM_ADD",
            sectionId: "POP_UP_CROUTON_MENU",
          },
        },
      },
      {
        card: {
          card: {
            "@type": "type.googleapis.com/swiggy.gandalf.widgets.v2.GridWidget",
            layout: {
              rows: 1,
              columns: 5,
              horizontalScrollEnabled: true,
              itemSpacing: 12,
              lineSpacing: 10,
              widgetPadding: {},
              containerStyle: {
                containerPadding: {
                  left: 10,
                  right: 10,
                  bottom: 16,
                },
              },
              scrollBar: {},
            },
            id: "offerCollectionWidget_UX4",
            gridElements: {
              infoWithStyle: {
                "@type":
                  "type.googleapis.com/swiggy.presentation.food.v2.OfferInfoWithStyle",
                offers: [
                  {
                    info: {
                      header: "10% OFF UPTO ₹40",
                      offerTagColor: "#E46D47",
                      offerIds: ["05b3be4b-db9f-4800-b7b5-d32d98c1f7ab"],
                      expiryTime: "1970-01-01T00:00:00Z",
                      couponCode: "USE TRYNEW",
                      description: "ABOVE ₹159",
                      offerType: "offers",
                      restId: "356251",
                      offerLogo: "offers/generic",
                      descriptionTextColor: "#7302060C",
                    },
                    cta: {
                      type: "OFFER_HALF_CARD",
                    },
                  },
                  {
                    info: {
                      header: "Free Tender Coconut Pudding",
                      offerTagColor: "#E46D47",
                      offerIds: ["4ba3f86e-2190-4a36-9457-be1301937728"],
                      expiryTime: "1970-01-01T00:00:00Z",
                      couponCode: "NO CODE REQUIRED",
                      description: "ABOVE ₹499",
                      offerType: "offers",
                      restId: "356251",
                      offerLogo: "offers/generic",
                      descriptionTextColor: "#7302060C",
                    },
                    cta: {
                      type: "OFFER_HALF_CARD",
                    },
                  },
                  {
                    info: {
                      header: "FLAT 10% OFF",
                      offerTag: "FLAT DEAL",
                      offerTagColor: "#E46D47",
                      offerIds: ["33ef1ebc-2e7b-4237-a2a6-34fec8f4d21d"],
                      expiryTime: "1970-01-01T00:00:00Z",
                      couponCode: "USE PARTY",
                      description: "ABOVE ₹500",
                      offerType: "offers",
                      restId: "356251",
                      offerLogo: "offers/generic",
                      descriptionTextColor: "#7302060C",
                    },
                    cta: {
                      type: "OFFER_HALF_CARD",
                    },
                  },
                  {
                    info: {
                      header: "30% OFF UPTO ₹75",
                      offerTagColor: "#E46D47",
                      logoBottom:
                        "rng/md/ads/production/3a937ddacd3adb70c4543c4895213123",
                      offerIds: ["e914d8fd-1192-49a8-97c9-24e29cd698e4"],
                      expiryTime: "1970-01-01T00:00:00Z",
                      couponCode: "NO CODE REQUIRED",
                      description: "ABOVE ₹199",
                      offerType: "offers",
                      restId: "356251",
                      offerLogo:
                        "rng/md/ads/production/3a937ddacd3adb70c4543c4895213123",
                      descriptionTextColor: "#7302060C",
                    },
                    cta: {
                      type: "OFFER_HALF_CARD",
                    },
                  },
                  {
                    info: {
                      header: "FLAT ₹150 OFF",
                      offerTagColor: "#E46D47",
                      logoBottom:
                        "rng/md/ads/production/59ef5e58052532ad85a185bf516eccce",
                      offerIds: ["edef4cff-cd2f-4b9e-8c62-9675d7b39129"],
                      expiryTime: "1970-01-01T00:00:00Z",
                      couponCode: "USE AMEX150",
                      description: "ABOVE ₹600",
                      offerType: "offers",
                      restId: "356251",
                      offerLogo:
                        "rng/md/ads/production/59ef5e58052532ad85a185bf516eccce",
                      descriptionTextColor: "#7302060C",
                    },
                    cta: {
                      type: "OFFER_HALF_CARD",
                    },
                  },
                ],
              },
            },
          },
        },
      },
      {
        groupedCard: {
          cardGroupMap: {
            REGULAR: {
              cards: [
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.MenuVegFilterAndBadge",
                      badges: {},
                      vegOnlyDetails: {
                        imageId: "AutoVegOnly_qkjowj",
                        title: "Showing only vegetarian options.",
                        description:
                          "Tap on the VEG ONLY button to turn off the setting",
                      },
                      topRatedFilter: {},
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Recommended",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379078",
                              name: "Bucket Chicken Biriyani",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "o3md7bihfchbxxrcfbgu",
                              inStock: 1,
                              price: 126000,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923477",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836224",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836259",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836211",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836204",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836269",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836272",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "90 ratings",
                                  ratingCountV2: "90",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379080",
                              name: "Bucket Biriyani With Chicken Kabab",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "ivjkz6qcirckeynmccj1",
                              inStock: 1,
                              price: 157500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923458",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836083",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836092",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836079",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836078",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836096",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836110",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "21 ratings",
                                  ratingCountV2: "21",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379081",
                              name: "Bucket Biriyani With Fiery Wings",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "qvnrbktvk7whi0qzcoau",
                              inStock: 1,
                              price: 163100,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923468",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836164",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836175",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836152",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836149",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836189",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836192",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379082",
                              name: "Bucket Biriyani With Kalmi Kabab",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "kmz6vppi99urueddhba4",
                              inStock: 1,
                              price: 167000,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923407",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835789",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835800",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835784",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835782",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835808",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835812",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378833",
                              name: "Empire Special Chilly Kebab",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "mwjxnsys86a5apetncxm",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396638",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 420,
                                        id: "31066696",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 215,
                                        id: "31066695",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 125,
                                        default: 1,
                                        id: "31066694",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396638",
                                        variationId: "31066696",
                                      },
                                    ],
                                    price: 42000,
                                    addonCombinations: [
                                      {
                                        groupId: "67840502",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396638",
                                        variationId: "31066695",
                                      },
                                    ],
                                    price: 21500,
                                    addonCombinations: [
                                      {
                                        groupId: "67840502",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396638",
                                        variationId: "31066694",
                                      },
                                    ],
                                    price: 12500,
                                    addonCombinations: [
                                      {
                                        groupId: "67840502",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67840502",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840509",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840498",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840520",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 12500,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "26 ratings",
                                  ratingCountV2: "26",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378960",
                              name: "Popcorn Chicken Kebab",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "irvoj3hrrcsu5t5n3wx8",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396694",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 490,
                                        id: "31066838",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066837",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 137,
                                        default: 1,
                                        id: "31066836",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396694",
                                        variationId: "31066838",
                                      },
                                    ],
                                    price: 49000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837596",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396694",
                                        variationId: "31066837",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "67837596",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396694",
                                        variationId: "31066836",
                                      },
                                    ],
                                    price: 13700,
                                    addonCombinations: [
                                      {
                                        groupId: "67837596",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837596",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837602",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837588",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837618",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13700,
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "603 ratings",
                                  ratingCountV2: "603",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378887",
                              name: "Empire Special Chicken Kebab",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "obqi7kmdulkmoyrbbx1g",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396661",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 377,
                                        id: "31066758",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 194,
                                        id: "31066757",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 107,
                                        default: 1,
                                        id: "31066756",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396661",
                                        variationId: "31066758",
                                      },
                                    ],
                                    price: 37700,
                                    addonCombinations: [
                                      {
                                        groupId: "67837408",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396661",
                                        variationId: "31066757",
                                      },
                                    ],
                                    price: 19400,
                                    addonCombinations: [
                                      {
                                        groupId: "67837408",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396661",
                                        variationId: "31066756",
                                      },
                                    ],
                                    price: 10700,
                                    addonCombinations: [
                                      {
                                        groupId: "67837408",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837414",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837408",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837407",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837421",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 10700,
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "2842 ratings",
                                  ratingCountV2: "2842",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378822",
                              name: "Lemon Rice",
                              category: "Rice",
                              description: "Serves 1",
                              imageId: "vgmmxxdnxv06pjgf3ksw",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808669",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 139,
                                        default: 1,
                                        id: "77096279",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 139,
                                        id: "77096280",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808669",
                                        variationId: "77096279",
                                      },
                                    ],
                                    price: 13900,
                                    addonCombinations: [
                                      {
                                        groupId: "67839436",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67839479",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808669",
                                        variationId: "77096280",
                                      },
                                    ],
                                    price: 13900,
                                    addonCombinations: [
                                      {
                                        groupId: "67839436",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67839479",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67839436",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839458",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839431",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839426",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839479",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839497",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13900,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.2",
                                  ratingCount: "11 ratings",
                                  ratingCountV2: "11",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378785",
                              name: "Chicken Tikka Masala",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "icpycagtwmqflonmjud8",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396620",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 515,
                                        id: "31066648",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 273,
                                        id: "31066647",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 153,
                                        default: 1,
                                        id: "31066646",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396620",
                                        variationId: "31066648",
                                      },
                                    ],
                                    price: 51500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923271",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396620",
                                        variationId: "31066647",
                                      },
                                    ],
                                    price: 27300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923271",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396620",
                                        variationId: "31066646",
                                      },
                                    ],
                                    price: 15300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923271",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923271",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835000",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835009",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835024",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15300,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "202 ratings",
                                  ratingCountV2: "202",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378791",
                              name: "Chicken Varval",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "mk3acltmonkzutknsqny",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396623",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 480,
                                        id: "31066655",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066654",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "31066653",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396623",
                                        variationId: "31066655",
                                      },
                                    ],
                                    price: 48000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923316",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396623",
                                        variationId: "31066654",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923316",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396623",
                                        variationId: "31066653",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923316",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923316",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835338",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835348",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835368",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.4",
                                  ratingCount: "227 ratings",
                                  ratingCountV2: "227",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378823",
                              name: "Butter Chicken Boneless",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "vbtgihmcmseeizl1iuma",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396636",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066690",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 265,
                                        id: "31066689",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 153,
                                        default: 1,
                                        id: "31066688",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396636",
                                        variationId: "31066690",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923745",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396636",
                                        variationId: "31066689",
                                      },
                                    ],
                                    price: 26500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923745",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396636",
                                        variationId: "31066688",
                                      },
                                    ],
                                    price: 15300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923745",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923745",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839601",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839607",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839624",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15300,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "155 ratings",
                                  ratingCountV2: "155",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378770",
                              name: "Ceylon Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "ediibdf3gsujqd6ifutq",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808666",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 37,
                                        default: 1,
                                        id: "77096273",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 37,
                                        id: "77096274",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808666",
                                        variationId: "77096273",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67838168",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808666",
                                        variationId: "77096274",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67838168",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67838168",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838179",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838204",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3700,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "414 ratings",
                                  ratingCountV2: "414",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379053",
                              name: "Coin Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "fmfupmuieyd62gfdcddh",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808667",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 30,
                                        default: 1,
                                        id: "77096275",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 30,
                                        id: "77096276",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808667",
                                        variationId: "77096275",
                                      },
                                    ],
                                    price: 3000,
                                    addonCombinations: [
                                      {
                                        groupId: "67838714",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808667",
                                        variationId: "77096276",
                                      },
                                    ],
                                    price: 3000,
                                    addonCombinations: [
                                      {
                                        groupId: "67838714",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67838714",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838743",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838755",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3000,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "664 ratings",
                                  ratingCountV2: "664",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378914",
                              name: "Kulcha",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "bzdjx3rqgccz6iuyyk0b",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "24090461",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 35,
                                        default: 1,
                                        id: "77727558",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 35,
                                        id: "77727559",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "24090461",
                                        variationId: "77727558",
                                      },
                                    ],
                                    price: 3500,
                                    addonCombinations: [
                                      {
                                        groupId: "67835381",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "24090461",
                                        variationId: "77727559",
                                      },
                                    ],
                                    price: 3500,
                                    addonCombinations: [
                                      {
                                        groupId: "67835381",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835381",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835404",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835410",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3500,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "2.8",
                                  ratingCount: "13 ratings",
                                  ratingCountV2: "13",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378858",
                              name: "Dal Fry",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "jxxtujwekfghedg6axk1",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396648",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 122,
                                        id: "31066724",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 71,
                                        default: 1,
                                        id: "31066723",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396648",
                                        variationId: "31066724",
                                      },
                                    ],
                                    price: 12200,
                                    addonCombinations: [
                                      {
                                        groupId: "68923969",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396648",
                                        variationId: "31066723",
                                      },
                                    ],
                                    price: 7100,
                                    addonCombinations: [
                                      {
                                        groupId: "68923969",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923969",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841796",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841801",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841842",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 7100,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "473 ratings",
                                  ratingCountV2: "473",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378897",
                              name: "Paneer Butter Masala",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "aebk3ggsozr3gtijronv",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396667",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 189,
                                        id: "31066773",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 112,
                                        default: 1,
                                        id: "31066772",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396667",
                                        variationId: "31066773",
                                      },
                                    ],
                                    price: 18900,
                                    addonCombinations: [
                                      {
                                        groupId: "68923638",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396667",
                                        variationId: "31066772",
                                      },
                                    ],
                                    price: 11200,
                                    addonCombinations: [
                                      {
                                        groupId: "68923638",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923638",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837921",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837925",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837971",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 11200,
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "77 ratings",
                                  ratingCountV2: "77",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "104038660",
                              name: "Chocolate Mousse",
                              category: "Juice Shop",
                              description: "Serves 1",
                              imageId: "rkufmtlhzq2ufiuqs5on",
                              inStock: 1,
                              isVeg: 1,
                              price: 7500,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "9 ratings",
                                  ratingCountV2: "9",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "104038661",
                              name: "Hot Chocolate Fudge",
                              category: "Juice Shop",
                              description: "Serves 1",
                              imageId: "l6sxj0dmltdp1yaoeaym",
                              inStock: 1,
                              isVeg: 1,
                              price: 14500,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "8 ratings",
                                  ratingCountV2: "8",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378996",
                              name: "Death By Chocolate",
                              category: "Juice Shop",
                              description:
                                "Serves 1 | DBC is everyone’s favourite. Scoops of rich and creamy vanilla ice cream with of a warm homemade chocolate cake sponge, drizzled with our signature homemade DBC sauce and roasted peanuts is an absolute treat for every dessert lover.",
                              imageId: "lvhxynldslndyn4u02ma",
                              inStock: 1,
                              isVeg: 1,
                              price: 16500,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.6",
                                  ratingCount: "13 ratings",
                                  ratingCountV2: "13",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "100221420",
                              name: "Matka Kulfi",
                              category: "Juice Shop",
                              description: "Serves 1",
                              imageId: "knpo2manobuiagemdkib",
                              inStock: 1,
                              isVeg: 1,
                              price: 8900,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              showImage: true,
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.8",
                                  ratingCount: "11 ratings",
                                  ratingCountV2: "11",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                      type: "CATEGORY_TYPE_RECOMMENDED",
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Biriyani",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378932",
                              name: "Egg Biriyani",
                              category: "Biriyani",
                              description: "Serves 1",
                              imageId: "estud4jfulsslglmzges",
                              inStock: 1,
                              price: 21400,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923509",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836446",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836497",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836443",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836439",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836501",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836510",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "12 ratings",
                                  ratingCountV2: "12",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378986",
                              name: "Prawns Biriyani",
                              category: "Biriyani",
                              description: "Serves 1",
                              imageId: "advclisbcydlpxmr8xds",
                              inStock: 1,
                              price: 33700,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923393",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835727",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67891660",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835725",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835721",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835741",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835750",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.5",
                                  ratingCount: "32 ratings",
                                  ratingCountV2: "32",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378776",
                              name: "Biriyani Rice (non Veg)",
                              category: "Biriyani",
                              description: "Serves 1",
                              imageId: "yaou4iakzffgz8zl8rhj",
                              inStock: 1,
                              price: 13000,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923618",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837844",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837861",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837837",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837831",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837869",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837874",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "432 ratings",
                                  ratingCountV2: "432",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378804",
                              name: "Chicken Biriyani",
                              category: "Biriyani",
                              description: "Serves 1",
                              imageId: "wxiqzeafmwowtfuskf1z",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396628",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "LARGE",
                                        price: 295,
                                        id: "31066668",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "REGULAR",
                                        price: 180,
                                        default: 1,
                                        id: "31066667",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396628",
                                        variationId: "31066668",
                                      },
                                    ],
                                    price: 29500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923433",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67870600",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67870600",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67870600",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67870599",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67870599",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67891662",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396628",
                                        variationId: "31066667",
                                      },
                                    ],
                                    price: 18000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923433",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67870601",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67870602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67870600",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67870600",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67870600",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67870599",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67870599",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67891662",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67870605",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923433",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67870601",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67870602",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67870600",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67870599",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67891662",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67870605",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 18000,
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "1501 ratings",
                                  ratingCountV2: "1501",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379001",
                              name: "Veg Biriyani",
                              category: "Biriyani",
                              description: "Serves 1",
                              imageId: "kklrjlpk5igqswxwfrga",
                              inStock: 1,
                              isVeg: 1,
                              price: 21400,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839856",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839891",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839821",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839812",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839902",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839903",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.7",
                                  ratingCount: "53 ratings",
                                  ratingCountV2: "53",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Thali",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "69091225",
                              name: "Veg Thali",
                              category: "Thali",
                              description: "Serves 1",
                              imageId: "89fe05a78fdfbf3d7964ff8fc35c6fa1",
                              inStock: 1,
                              isVeg: 1,
                              price: 22900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67838638",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838652",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838685",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838648",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838698",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "20 ratings",
                                  ratingCountV2: "20",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378942",
                              name: "Fish Thali",
                              category: "Thali",
                              description: "Serves 1",
                              inStock: 1,
                              price: 35900,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "4 ratings",
                                  ratingCountV2: "4",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Empire Special Combo Meals",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624560",
                              name: "Ghee Rice With Dal Fry",
                              category: "Empire Special Combo Meals",
                              description: "Serves 1 | Ghee Rice, Dal Fry.",
                              inStock: 1,
                              isVeg: 1,
                              price: 14900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67837326",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837330",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837337",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837324",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837345",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.7",
                                  ratingCount: "46 ratings",
                                  ratingCountV2: "46",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624562",
                              name: "Ghee Rice with Paneer Butter Masala & Gobi Manchurian",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | Ghee Rice, Paneer Butter Masala (5 pcs), Gobi Manchurian",
                              inStock: 1,
                              isVeg: 1,
                              price: 16900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67891667",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67891669",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67891672",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67891665",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67891673",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "51 ratings",
                                  ratingCountV2: "51",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624563",
                              name: "Veg Fried Rice & Gobi Manchurian",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | Veg Fried Rice, Gobi Manchurian.",
                              inStock: 1,
                              isVeg: 1,
                              price: 14900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67837157",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837161",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837169",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837147",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837177",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "24 ratings",
                                  ratingCountV2: "24",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624553",
                              name: "2 Naan, Paneer Butter Masala, Gobi Manchurian",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | 2Naan,Paneer Butter Masala (5 pcs),Gobi Manchurian",
                              inStock: 1,
                              isVeg: 1,
                              price: 16900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67840740",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840756",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840797",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840733",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840820",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.7",
                                  ratingCount: "125 ratings",
                                  ratingCountV2: "125",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624551",
                              name: "3 Coin Parotta, Hyderabadi Boneless Chicken Gravy & Popcorn Kebab",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | Coin Parotta (2 Nos), Hyderabadi Chicken Boneless (5 Pcs), Popcorn Kebab (5 Pcs)",
                              inStock: 1,
                              price: 20900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67838229",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838235",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838248",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838227",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838257",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "30 ratings",
                                  ratingCountV2: "30",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624554",
                              name: "Biriyani Rice, Raitha & Pepper Chicken Boneless",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | Biriyani Rice, Raitha, Pepper Chicken Bonless (5 Pcs)",
                              inStock: 1,
                              price: 16900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923915",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840888",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840902",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840940",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840896",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840872",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840966",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "49 ratings",
                                  ratingCountV2: "49",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624555",
                              name: "Chicken Biriyani (Regular), Raitha &Popcorn Kebab",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | Chicken Biriyani (Regular), Raitha, Popcorn Kebab (5 Pcs)",
                              inStock: 1,
                              price: 20900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923938",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841504",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841513",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841539",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841509",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841488",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841562",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "71 ratings",
                                  ratingCountV2: "71",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624556",
                              name: "Ghee Rice Butter Chicken Combo",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | Ghee Rice, Butter Chicken Boneless (5 Pcs).",
                              inStock: 1,
                              price: 14900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67838879",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838885",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838901",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838877",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838937",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.4",
                                  ratingCount: "100 ratings",
                                  ratingCountV2: "100",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "85624557",
                              name: "Ghee Rice, Butter Chicken Combo & Chicken Manchurian Dry",
                              category: "Empire Special Combo Meals",
                              description:
                                "Serves 1 | Ghee Rice, Butter Chicken Boneless (5 Pcs), Manchurian Dry (5 Pcs)",
                              inStock: 1,
                              price: 18900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839021",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839031",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839046",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839015",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839060",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "105 ratings",
                                  ratingCountV2: "105",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Empire Special Biryani",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379018",
                              name: "Mutton Raan Biriyani",
                              category: "Empire Special Biryani",
                              description: "Serves 1",
                              imageId: "sqnozrogkkcx4qfdban7",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396727",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 1734,
                                        id: "31066922",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 894,
                                        default: 1,
                                        id: "31066921",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396727",
                                        variationId: "31066922",
                                      },
                                    ],
                                    price: 173400,
                                    addonCombinations: [
                                      {
                                        groupId: "68923851",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67840020",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840020",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840020",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840015",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840015",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840015",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840013",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67840013",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67840063",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396727",
                                        variationId: "31066921",
                                      },
                                    ],
                                    price: 89400,
                                    addonCombinations: [
                                      {
                                        groupId: "68923851",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67840028",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67840020",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840020",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840020",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840051",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840015",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840015",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840015",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840013",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67840013",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67840063",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840066",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923851",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840028",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840051",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840020",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840015",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840013",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840063",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840066",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 89400,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "32 ratings",
                                  ratingCountV2: "32",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Bucket Biriyani",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379078",
                              name: "Bucket Chicken Biriyani",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "o3md7bihfchbxxrcfbgu",
                              inStock: 1,
                              price: 126000,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923477",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836224",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836259",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836211",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836204",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836269",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836272",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "90 ratings",
                                  ratingCountV2: "90",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379080",
                              name: "Bucket Biriyani With Chicken Kabab",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "ivjkz6qcirckeynmccj1",
                              inStock: 1,
                              price: 157500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923458",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836083",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836092",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836079",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836078",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836096",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836110",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "21 ratings",
                                  ratingCountV2: "21",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379081",
                              name: "Bucket Biriyani With Fiery Wings",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "qvnrbktvk7whi0qzcoau",
                              inStock: 1,
                              price: 163100,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923468",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836164",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836175",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836152",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836149",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836189",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836192",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379082",
                              name: "Bucket Biriyani With Kalmi Kabab",
                              category: "Bucket Biriyani",
                              description: "Serves 7",
                              imageId: "kmz6vppi99urueddhba4",
                              inStock: 1,
                              price: 167000,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923407",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835789",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835800",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835784",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835782",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835808",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835812",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 7",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Shaadi Ki Biriyani",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "69091187",
                              name: "Shaadi Ki Biriyani (Mutton)",
                              category: "Shaadi Ki Biriyani",
                              description: "Serves 1",
                              imageId: "ceerfe2cawraetafjd8w",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "10074073",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "1 Portion",
                                        price: 325,
                                        default: 1,
                                        id: "33236841",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "0.5kg",
                                        price: 1301,
                                        id: "33236842",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "1kg",
                                        price: 2086,
                                        id: "33236843",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "2kg",
                                        price: 3848,
                                        id: "33236844",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "10074073",
                                        variationId: "33236841",
                                      },
                                    ],
                                    price: 32500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923356",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67835639",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "10074073",
                                        variationId: "33236842",
                                      },
                                    ],
                                    price: 130100,
                                    addonCombinations: [
                                      {
                                        groupId: "68923356",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67835639",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "10074073",
                                        variationId: "33236843",
                                      },
                                    ],
                                    price: 208600,
                                    addonCombinations: [
                                      {
                                        groupId: "68923356",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67835639",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "10074073",
                                        variationId: "33236844",
                                      },
                                    ],
                                    price: 384800,
                                    addonCombinations: [
                                      {
                                        groupId: "68923356",
                                        addonId: "54935037",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835613",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835628",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835604",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67835600",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67835639",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835644",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923356",
                                  groupName: "Egg",
                                  choices: [
                                    {
                                      id: "54935037",
                                      name: "Boiled Egg",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835613",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835628",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835604",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835600",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835639",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835644",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 32500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "366 ratings",
                                  ratingCountV2: "366",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Empire Special Chicken (Dry)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378954",
                              name: "Pepper Chicken Dry Boneless",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "jzjh2o2tntz1kpp8bxug",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396691",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 480,
                                        id: "31066830",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 252,
                                        id: "31066829",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "31066828",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396691",
                                        variationId: "31066830",
                                      },
                                    ],
                                    price: 48000,
                                    addonCombinations: [
                                      {
                                        groupId: "67840426",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840426",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840426",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396691",
                                        variationId: "31066829",
                                      },
                                    ],
                                    price: 25200,
                                    addonCombinations: [
                                      {
                                        groupId: "67840426",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840426",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840426",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396691",
                                        variationId: "31066828",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "67840426",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840426",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840426",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840440",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840415",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840467",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67840426",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840440",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840415",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840467",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "172 ratings",
                                  ratingCountV2: "172",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378833",
                              name: "Empire Special Chilly Kebab",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "mwjxnsys86a5apetncxm",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396638",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 420,
                                        id: "31066696",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 215,
                                        id: "31066695",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 125,
                                        default: 1,
                                        id: "31066694",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396638",
                                        variationId: "31066696",
                                      },
                                    ],
                                    price: 42000,
                                    addonCombinations: [
                                      {
                                        groupId: "67840502",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396638",
                                        variationId: "31066695",
                                      },
                                    ],
                                    price: 21500,
                                    addonCombinations: [
                                      {
                                        groupId: "67840502",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396638",
                                        variationId: "31066694",
                                      },
                                    ],
                                    price: 12500,
                                    addonCombinations: [
                                      {
                                        groupId: "67840502",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840502",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840509",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840498",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840520",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67840502",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840509",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840498",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840520",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 12500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "26 ratings",
                                  ratingCountV2: "26",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378946",
                              name: "Pepper Chicken Dry",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "nrx3bnvkrvjblpwnejze",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396688",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 449,
                                        id: "31066822",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 235,
                                        id: "31066821",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 133,
                                        default: 1,
                                        id: "31066820",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396688",
                                        variationId: "31066822",
                                      },
                                    ],
                                    price: 44900,
                                    addonCombinations: [
                                      {
                                        groupId: "67839559",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67839559",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67839559",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396688",
                                        variationId: "31066821",
                                      },
                                    ],
                                    price: 23500,
                                    addonCombinations: [
                                      {
                                        groupId: "67839559",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67839559",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67839559",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396688",
                                        variationId: "31066820",
                                      },
                                    ],
                                    price: 13300,
                                    addonCombinations: [
                                      {
                                        groupId: "67839559",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67839559",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67839559",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67839563",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839550",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839576",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67839559",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839563",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839550",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839576",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "2.8",
                                  ratingCount: "12 ratings",
                                  ratingCountV2: "12",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378960",
                              name: "Popcorn Chicken Kebab",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "irvoj3hrrcsu5t5n3wx8",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396694",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 490,
                                        id: "31066838",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066837",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 137,
                                        default: 1,
                                        id: "31066836",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396694",
                                        variationId: "31066838",
                                      },
                                    ],
                                    price: 49000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837596",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396694",
                                        variationId: "31066837",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "67837596",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396694",
                                        variationId: "31066836",
                                      },
                                    ],
                                    price: 13700,
                                    addonCombinations: [
                                      {
                                        groupId: "67837596",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837596",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837602",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837588",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837618",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837596",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837602",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837588",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837618",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13700,
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "603 ratings",
                                  ratingCountV2: "603",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378761",
                              name: "Alfaham Chicken",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "2e675d94449140ec4a4954c992533725",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396610",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 462,
                                        id: "31066624",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 245,
                                        id: "31066623",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 138,
                                        default: 1,
                                        id: "31066622",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396610",
                                        variationId: "31066624",
                                      },
                                    ],
                                    price: 46200,
                                    addonCombinations: [
                                      {
                                        groupId: "67838972",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838972",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838972",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156397",
                                      },
                                      {
                                        groupId: "67870619",
                                        addonId: "54172285",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396610",
                                        variationId: "31066623",
                                      },
                                    ],
                                    price: 24500,
                                    addonCombinations: [
                                      {
                                        groupId: "67838972",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838972",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838972",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156397",
                                      },
                                      {
                                        groupId: "67870619",
                                        addonId: "54172285",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396610",
                                        variationId: "31066622",
                                      },
                                    ],
                                    price: 13800,
                                    addonCombinations: [
                                      {
                                        groupId: "67838972",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838972",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838972",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838982",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838968",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838994",
                                        addonId: "54156397",
                                      },
                                      {
                                        groupId: "67870619",
                                        addonId: "54172285",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67838982",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838972",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838968",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838994",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67870619",
                                  groupName: "Bread",
                                  choices: [
                                    {
                                      id: "54172285",
                                      name: "Kuboos",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13800,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "493 ratings",
                                  ratingCountV2: "493",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378811",
                              name: "Chilly Chicken Boneless",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "jkyfek2e4uuwxduqec89",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396631",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 470,
                                        id: "31066677",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 247,
                                        id: "31066676",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 140,
                                        default: 1,
                                        id: "31066675",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396631",
                                        variationId: "31066677",
                                      },
                                    ],
                                    price: 47000,
                                    addonCombinations: [
                                      {
                                        groupId: "67836710",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67836710",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67836710",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396631",
                                        variationId: "31066676",
                                      },
                                    ],
                                    price: 24700,
                                    addonCombinations: [
                                      {
                                        groupId: "67836710",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67836710",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67836710",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396631",
                                        variationId: "31066675",
                                      },
                                    ],
                                    price: 14000,
                                    addonCombinations: [
                                      {
                                        groupId: "67836710",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67836710",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67836710",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67836713",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836703",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836720",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67836710",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836713",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836703",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836720",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "95 ratings",
                                  ratingCountV2: "95",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378830",
                              name: "Chicken Kebab Boneless",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "sexytz31r1pqfx5xomd5",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396637",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 490,
                                        id: "31066693",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066692",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 132,
                                        default: 1,
                                        id: "31066691",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396637",
                                        variationId: "31066693",
                                      },
                                    ],
                                    price: 49000,
                                    addonCombinations: [
                                      {
                                        groupId: "67840348",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840348",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840348",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396637",
                                        variationId: "31066692",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "67840348",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840348",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840348",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396637",
                                        variationId: "31066691",
                                      },
                                    ],
                                    price: 13200,
                                    addonCombinations: [
                                      {
                                        groupId: "67840348",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67840348",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67840348",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840353",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67840342",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840378",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67840348",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840353",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840342",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840378",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13200,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "214 ratings",
                                  ratingCountV2: "214",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378887",
                              name: "Empire Special Chicken Kebab",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "obqi7kmdulkmoyrbbx1g",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396661",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 377,
                                        id: "31066758",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 194,
                                        id: "31066757",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 107,
                                        default: 1,
                                        id: "31066756",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396661",
                                        variationId: "31066758",
                                      },
                                    ],
                                    price: 37700,
                                    addonCombinations: [
                                      {
                                        groupId: "67837408",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396661",
                                        variationId: "31066757",
                                      },
                                    ],
                                    price: 19400,
                                    addonCombinations: [
                                      {
                                        groupId: "67837408",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396661",
                                        variationId: "31066756",
                                      },
                                    ],
                                    price: 10700,
                                    addonCombinations: [
                                      {
                                        groupId: "67837408",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837408",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837414",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837407",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837421",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837414",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837408",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837407",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837421",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 10700,
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "2842 ratings",
                                  ratingCountV2: "2842",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379051",
                              name: "Chicken Manchurian Boneless",
                              category: "Empire Special Chicken (Dry)",
                              description: "Serves 1",
                              imageId: "gloajcuovqkezogtpfud",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396740",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 484,
                                        id: "31066956",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 252,
                                        id: "31066955",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 137,
                                        default: 1,
                                        id: "31066954",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396740",
                                        variationId: "31066956",
                                      },
                                    ],
                                    price: 48400,
                                    addonCombinations: [
                                      {
                                        groupId: "67838524",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838524",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838524",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396740",
                                        variationId: "31066955",
                                      },
                                    ],
                                    price: 25200,
                                    addonCombinations: [
                                      {
                                        groupId: "67838524",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838524",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838524",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396740",
                                        variationId: "31066954",
                                      },
                                    ],
                                    price: 13700,
                                    addonCombinations: [
                                      {
                                        groupId: "67838524",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838524",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838524",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838527",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838518",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838540",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67838527",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838524",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838518",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838540",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "15 ratings",
                                  ratingCountV2: "15",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Tandoori(dry)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378893",
                              name: "Tandoori Chicken",
                              category: "Tandoori(dry)",
                              description: "Serves 1",
                              imageId: "gb5pcoodwyyssr9fjgjl",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396665",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 460,
                                        id: "31066768",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 245,
                                        id: "31066767",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 140,
                                        default: 1,
                                        id: "31066766",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396665",
                                        variationId: "31066768",
                                      },
                                    ],
                                    price: 46000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837715",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837715",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837715",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156397",
                                      },
                                      {
                                        groupId: "67870614",
                                        addonId: "54172285",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396665",
                                        variationId: "31066767",
                                      },
                                    ],
                                    price: 24500,
                                    addonCombinations: [
                                      {
                                        groupId: "67837715",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837715",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837715",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156397",
                                      },
                                      {
                                        groupId: "67870614",
                                        addonId: "54172285",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396665",
                                        variationId: "31066766",
                                      },
                                    ],
                                    price: 14000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837715",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837715",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837715",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837707",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837718",
                                        addonId: "54156397",
                                      },
                                      {
                                        groupId: "67870614",
                                        addonId: "54172285",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837715",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837707",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837718",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67870614",
                                  groupName: "Bread",
                                  choices: [
                                    {
                                      id: "54172285",
                                      name: "Kuboos",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "70 ratings",
                                  ratingCountV2: "70",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378924",
                              name: "Murg Hariyali Tikka",
                              category: "Tandoori(dry)",
                              description: "Serves 1",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396680",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066805",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066804",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 150,
                                        default: 1,
                                        id: "31066803",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396680",
                                        variationId: "31066805",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "67835888",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67835888",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67835888",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396680",
                                        variationId: "31066804",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "67835888",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67835888",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67835888",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396680",
                                        variationId: "31066803",
                                      },
                                    ],
                                    price: 15000,
                                    addonCombinations: [
                                      {
                                        groupId: "67835888",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67835888",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67835888",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835883",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835890",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835888",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835883",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835890",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.5",
                                  ratingCount: "4 ratings",
                                  ratingCountV2: "4",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378992",
                              name: "Kalmi Kabab",
                              category: "Tandoori(dry)",
                              description: "Serves 1",
                              imageId: "ymipyekw4xeq0mkufsl8",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396711",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066883",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 275,
                                        id: "31066882",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 150,
                                        default: 1,
                                        id: "31066881",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396711",
                                        variationId: "31066883",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837085",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837085",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837085",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396711",
                                        variationId: "31066882",
                                      },
                                    ],
                                    price: 27500,
                                    addonCombinations: [
                                      {
                                        groupId: "67837085",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837085",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837085",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396711",
                                        variationId: "31066881",
                                      },
                                    ],
                                    price: 15000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837085",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837085",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837085",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67837079",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837086",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837085",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837079",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837086",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "70 ratings",
                                  ratingCountV2: "70",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378997",
                              name: "Murg Tikka",
                              category: "Tandoori(dry)",
                              description: "Serves 1",
                              imageId: "nncv1qc5djsgljrs2eoz",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396714",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066890",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066889",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 150,
                                        default: 1,
                                        id: "31066888",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396714",
                                        variationId: "31066890",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "67836551",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67836551",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67836551",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396714",
                                        variationId: "31066889",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "67836551",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67836551",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67836551",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396714",
                                        variationId: "31066888",
                                      },
                                    ],
                                    price: 15000,
                                    addonCombinations: [
                                      {
                                        groupId: "67836551",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67836551",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67836551",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836543",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836561",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67836551",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836543",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836561",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.5",
                                  ratingCount: "26 ratings",
                                  ratingCountV2: "26",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Empire Special Mutton (Dry)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378999",
                              name: "Mutton Pepper Dry",
                              category: "Empire Special Mutton (Dry)",
                              description: "Serves 1",
                              imageId: "tbbdp3koyjvnutggkual",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396715",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 630,
                                        id: "31066893",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 330,
                                        id: "31066892",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 185,
                                        default: 1,
                                        id: "31066891",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396715",
                                        variationId: "31066893",
                                      },
                                    ],
                                    price: 63000,
                                    addonCombinations: [
                                      {
                                        groupId: "67836409",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396715",
                                        variationId: "31066892",
                                      },
                                    ],
                                    price: 33000,
                                    addonCombinations: [
                                      {
                                        groupId: "67836409",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396715",
                                        variationId: "31066891",
                                      },
                                    ],
                                    price: 18500,
                                    addonCombinations: [
                                      {
                                        groupId: "67836409",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836409",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67836409",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 18500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "2.8",
                                  ratingCount: "16 ratings",
                                  ratingCountV2: "16",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379003",
                              name: "Mutton Pepper Dry Boneless",
                              category: "Empire Special Mutton (Dry)",
                              description: "Serves 1",
                              imageId: "r31xfm9x08iok9q0d5qo",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396718",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 645,
                                        id: "31066900",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 340,
                                        id: "31066899",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 189,
                                        default: 1,
                                        id: "31066898",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396718",
                                        variationId: "31066900",
                                      },
                                    ],
                                    price: 64500,
                                    addonCombinations: [
                                      {
                                        groupId: "67839235",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396718",
                                        variationId: "31066899",
                                      },
                                    ],
                                    price: 34000,
                                    addonCombinations: [
                                      {
                                        groupId: "67839235",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396718",
                                        variationId: "31066898",
                                      },
                                    ],
                                    price: 18900,
                                    addonCombinations: [
                                      {
                                        groupId: "67839235",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839235",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67839235",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 18900,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.5",
                                  ratingCount: "16 ratings",
                                  ratingCountV2: "16",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379013",
                              name: "Mutton Raan",
                              category: "Empire Special Mutton (Dry)",
                              description: "Serves 1",
                              imageId: "gge20xy6rhc5xgr2urit",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396723",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 1377,
                                        id: "31066912",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 714,
                                        default: 1,
                                        id: "31066911",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396723",
                                        variationId: "31066912",
                                      },
                                    ],
                                    price: 137700,
                                    addonCombinations: [
                                      {
                                        groupId: "67839960",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67839960",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67839960",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67839952",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839952",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839952",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396723",
                                        variationId: "31066911",
                                      },
                                    ],
                                    price: 71400,
                                    addonCombinations: [
                                      {
                                        groupId: "67839960",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67839960",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67839960",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67839952",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839952",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839952",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839982",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67839960",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839952",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839982",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 71400,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "2.7",
                                  ratingCount: "20 ratings",
                                  ratingCountV2: "20",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379031",
                              name: "Mutton Brain Dry Fry",
                              category: "Empire Special Mutton (Dry)",
                              description: "Serves 1",
                              imageId: "yjktl5fctf4g4solnqcy",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396731",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 680,
                                        id: "31066934",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 340,
                                        id: "31066933",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 170,
                                        default: 1,
                                        id: "31066932",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396731",
                                        variationId: "31066934",
                                      },
                                    ],
                                    price: 68000,
                                    addonCombinations: [
                                      {
                                        groupId: "67841258",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396731",
                                        variationId: "31066933",
                                      },
                                    ],
                                    price: 34000,
                                    addonCombinations: [
                                      {
                                        groupId: "67841258",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396731",
                                        variationId: "31066932",
                                      },
                                    ],
                                    price: 17000,
                                    addonCombinations: [
                                      {
                                        groupId: "67841258",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841258",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67841258",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 17000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "154 ratings",
                                  ratingCountV2: "154",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Rice",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "96068774",
                              name: "Curd Rice",
                              category: "Rice",
                              description: "Serves 1",
                              inStock: 1,
                              isVeg: 1,
                              price: 13900,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67836603",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836612",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836601",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836597",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836620",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "36 ratings",
                                  ratingCountV2: "36",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "117673915",
                              name: "Plain Rice",
                              category: "Rice",
                              description: "Serves 1",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "28683759",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 70,
                                        default: 1,
                                        id: "90335365",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 70,
                                        id: "90335366",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "28683759",
                                        variationId: "90335365",
                                      },
                                    ],
                                    price: 7000,
                                    addonCombinations: [
                                      {
                                        groupId: "94505576",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "94505598",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "94505598",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "94505598",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "94505605",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "94505605",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "94505616",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "28683759",
                                        variationId: "90335366",
                                      },
                                    ],
                                    price: 7000,
                                    addonCombinations: [
                                      {
                                        groupId: "94505576",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "94505576",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "94505587",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "94505598",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "94505598",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "94505598",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "94505605",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "94505605",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "94505616",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "94505623",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "94505576",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "94505587",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "94505598",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "94505605",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "94505616",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "94505623",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 7000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378795",
                              name: "Ghee Rice",
                              category: "Rice",
                              description: "Serves 1",
                              imageId: "troivpi2v0ybmdzwwz7l",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808615",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 101,
                                        default: 1,
                                        id: "77096171",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 101,
                                        id: "77096172",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808615",
                                        variationId: "77096171",
                                      },
                                    ],
                                    price: 10100,
                                    addonCombinations: [
                                      {
                                        groupId: "67835479",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835474",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835474",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835474",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835470",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67835470",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67835501",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808615",
                                        variationId: "77096172",
                                      },
                                    ],
                                    price: 10100,
                                    addonCombinations: [
                                      {
                                        groupId: "67835479",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835479",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835487",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835474",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67835474",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67835474",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67835470",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67835470",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67835501",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835506",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835479",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835487",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835474",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835470",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835501",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835506",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 10100,
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "1833 ratings",
                                  ratingCountV2: "1833",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378816",
                              name: "Jeera Rice",
                              category: "Rice",
                              description: "Serves 1",
                              imageId: "vk0r9hgvonmw9zd4ymwp",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808645",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 139,
                                        default: 1,
                                        id: "77096231",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 139,
                                        id: "77096232",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808645",
                                        variationId: "77096231",
                                      },
                                    ],
                                    price: 13900,
                                    addonCombinations: [
                                      {
                                        groupId: "67836963",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67836949",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836949",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836949",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836939",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67836939",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67836994",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808645",
                                        variationId: "77096232",
                                      },
                                    ],
                                    price: 13900,
                                    addonCombinations: [
                                      {
                                        groupId: "67836963",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836963",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67836986",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67836949",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67836949",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67836949",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67836939",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67836939",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67836994",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836996",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67836963",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836986",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836949",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836939",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836994",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836996",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13900,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.7",
                                  ratingCount: "47 ratings",
                                  ratingCountV2: "47",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378822",
                              name: "Lemon Rice",
                              category: "Rice",
                              description: "Serves 1",
                              imageId: "vgmmxxdnxv06pjgf3ksw",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808669",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 139,
                                        default: 1,
                                        id: "77096279",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 139,
                                        id: "77096280",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808669",
                                        variationId: "77096279",
                                      },
                                    ],
                                    price: 13900,
                                    addonCombinations: [
                                      {
                                        groupId: "67839436",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67839479",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808669",
                                        variationId: "77096280",
                                      },
                                    ],
                                    price: 13900,
                                    addonCombinations: [
                                      {
                                        groupId: "67839436",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839436",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67839458",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67839431",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67839426",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67839479",
                                        addonId: "54156503",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839497",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67839436",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839458",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839431",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839426",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839479",
                                  groupName: "Dessert",
                                  choices: [
                                    {
                                      id: "54156503",
                                      name: "Tender Coconut Pudding",
                                      price: 7100,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839497",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13900,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.2",
                                  ratingCount: "11 ratings",
                                  ratingCountV2: "11",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Empire Special Chicken (Gravy)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378959",
                              name: "Pepper Chicken Masala",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "flq53krkpkjkhod7zrwr",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396693",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 480,
                                        id: "31066835",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066834",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "31066833",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396693",
                                        variationId: "31066835",
                                      },
                                    ],
                                    price: 48000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923595",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396693",
                                        variationId: "31066834",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923595",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396693",
                                        variationId: "31066833",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923595",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837541",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837548",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837558",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923595",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837541",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837548",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837558",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "68 ratings",
                                  ratingCountV2: "68",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378778",
                              name: "Chicken Tandoori Masala",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "l9jc37cdfdc0nnpk49ln",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396614",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 510,
                                        id: "31066635",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 275,
                                        id: "31066634",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 158,
                                        default: 1,
                                        id: "31066633",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396614",
                                        variationId: "31066635",
                                      },
                                    ],
                                    price: 51000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923651",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396614",
                                        variationId: "31066634",
                                      },
                                    ],
                                    price: 27500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923651",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396614",
                                        variationId: "31066633",
                                      },
                                    ],
                                    price: 15800,
                                    addonCombinations: [
                                      {
                                        groupId: "68923651",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838016",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838021",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838034",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923651",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838016",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838021",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838034",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15800,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "22 ratings",
                                  ratingCountV2: "22",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378785",
                              name: "Chicken Tikka Masala",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "icpycagtwmqflonmjud8",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396620",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 515,
                                        id: "31066648",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 273,
                                        id: "31066647",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 153,
                                        default: 1,
                                        id: "31066646",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396620",
                                        variationId: "31066648",
                                      },
                                    ],
                                    price: 51500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923271",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396620",
                                        variationId: "31066647",
                                      },
                                    ],
                                    price: 27300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923271",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396620",
                                        variationId: "31066646",
                                      },
                                    ],
                                    price: 15300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923271",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835000",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835009",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835024",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923271",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835000",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835009",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835024",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "202 ratings",
                                  ratingCountV2: "202",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378791",
                              name: "Chicken Varval",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "mk3acltmonkzutknsqny",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396623",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 480,
                                        id: "31066655",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066654",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "31066653",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396623",
                                        variationId: "31066655",
                                      },
                                    ],
                                    price: 48000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923316",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396623",
                                        variationId: "31066654",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923316",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396623",
                                        variationId: "31066653",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923316",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835348",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835368",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923316",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835338",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835348",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835368",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.4",
                                  ratingCount: "227 ratings",
                                  ratingCountV2: "227",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378815",
                              name: "Butter Chicken",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "kywkfwwyqscindrzh1fa",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396632",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 484,
                                        id: "31066680",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066679",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "31066678",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396632",
                                        variationId: "31066680",
                                      },
                                    ],
                                    price: 48400,
                                    addonCombinations: [
                                      {
                                        groupId: "68923531",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396632",
                                        variationId: "31066679",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923531",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396632",
                                        variationId: "31066678",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923531",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67836859",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836871",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836891",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923531",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836859",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836871",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836891",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.7",
                                  ratingCount: "45 ratings",
                                  ratingCountV2: "45",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378823",
                              name: "Butter Chicken Boneless",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "vbtgihmcmseeizl1iuma",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396636",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066690",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 265,
                                        id: "31066689",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 153,
                                        default: 1,
                                        id: "31066688",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396636",
                                        variationId: "31066690",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923745",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396636",
                                        variationId: "31066689",
                                      },
                                    ],
                                    price: 26500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923745",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396636",
                                        variationId: "31066688",
                                      },
                                    ],
                                    price: 15300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923745",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67839601",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67839607",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67839624",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923745",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839601",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839607",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839624",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "155 ratings",
                                  ratingCountV2: "155",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378860",
                              name: "Chicken Kurma",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396649",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 490,
                                        id: "31066727",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066726",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "31066725",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396649",
                                        variationId: "31066727",
                                      },
                                    ],
                                    price: 49000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923549",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396649",
                                        variationId: "31066726",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923549",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396649",
                                        variationId: "31066725",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923549",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837202",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837204",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837224",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923549",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837202",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837204",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837224",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "26 ratings",
                                  ratingCountV2: "26",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378900",
                              name: "Chicken Manchurian Masala",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "ks5nvn1d9ufzq7bxifvu",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396669",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066778",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 265,
                                        id: "31066777",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 153,
                                        default: 1,
                                        id: "31066776",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396669",
                                        variationId: "31066778",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923285",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396669",
                                        variationId: "31066777",
                                      },
                                    ],
                                    price: 26500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923285",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396669",
                                        variationId: "31066776",
                                      },
                                    ],
                                    price: 15300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923285",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835105",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835108",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835115",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923285",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835105",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835108",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835115",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "16 ratings",
                                  ratingCountV2: "16",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378908",
                              name: "Chicken Masala",
                              category: "Empire Special Chicken (Gravy)",
                              description: "Serves 1",
                              imageId: "jnd6i6omxpfa3nosk8zy",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396672",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 484,
                                        id: "31066785",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "31066784",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "31066783",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396672",
                                        variationId: "31066785",
                                      },
                                    ],
                                    price: 48400,
                                    addonCombinations: [
                                      {
                                        groupId: "68923280",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396672",
                                        variationId: "31066784",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923280",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396672",
                                        variationId: "31066783",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923280",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835056",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835060",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835066",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923280",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835056",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835060",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835066",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "37 ratings",
                                  ratingCountV2: "37",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Empire Special Mutton (Gravy)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378975",
                              name: "Pepper Mutton Masala",
                              category: "Empire Special Mutton (Gravy)",
                              description: "Serves 1",
                              imageId: "smhvpyjoep9h1buvkpfl",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396701",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 627,
                                        id: "31066858",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 322,
                                        id: "31066857",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 170,
                                        default: 1,
                                        id: "31066856",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396701",
                                        variationId: "31066858",
                                      },
                                    ],
                                    price: 62700,
                                    addonCombinations: [
                                      {
                                        groupId: "68923383",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396701",
                                        variationId: "31066857",
                                      },
                                    ],
                                    price: 32200,
                                    addonCombinations: [
                                      {
                                        groupId: "68923383",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396701",
                                        variationId: "31066856",
                                      },
                                    ],
                                    price: 17000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923383",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835687",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835698",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835708",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923383",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835687",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835698",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835708",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 17000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "14 ratings",
                                  ratingCountV2: "14",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378961",
                              name: "Mutton Kurma",
                              category: "Empire Special Mutton (Gravy)",
                              description: "Serves 1",
                              imageId: "twjmxjokmjh7g0a7atwf",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396695",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 630,
                                        id: "31066841",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 325,
                                        id: "31066840",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 173,
                                        default: 1,
                                        id: "31066839",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396695",
                                        variationId: "31066841",
                                      },
                                    ],
                                    price: 63000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923295",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396695",
                                        variationId: "31066840",
                                      },
                                    ],
                                    price: 32500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923295",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396695",
                                        variationId: "31066839",
                                      },
                                    ],
                                    price: 17300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923295",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835171",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835178",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835191",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923295",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835171",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835178",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835191",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 17300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "39 ratings",
                                  ratingCountV2: "39",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379023",
                              name: "Mutton Varuval",
                              category: "Empire Special Mutton (Gravy)",
                              description: "Serves 1",
                              imageId: "bmeksa4b6do9jkpxurz7",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396728",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 630,
                                        id: "31066925",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 325,
                                        id: "31066924",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 173,
                                        default: 1,
                                        id: "31066923",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396728",
                                        variationId: "31066925",
                                      },
                                    ],
                                    price: 63000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923928",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396728",
                                        variationId: "31066924",
                                      },
                                    ],
                                    price: 32500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923928",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396728",
                                        variationId: "31066923",
                                      },
                                    ],
                                    price: 17300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923928",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841422",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841428",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841463",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923928",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841422",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841428",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841463",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 17300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "12 ratings",
                                  ratingCountV2: "12",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379034",
                              name: "Mutton Brain Masala",
                              category: "Empire Special Mutton (Gravy)",
                              description: "Serves 1",
                              imageId: "adqdbgha4yupdvrd3lmx",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396733",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 693,
                                        id: "31066940",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 346,
                                        id: "31066939",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 173,
                                        default: 1,
                                        id: "31066938",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396733",
                                        variationId: "31066940",
                                      },
                                    ],
                                    price: 69300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923972",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396733",
                                        variationId: "31066939",
                                      },
                                    ],
                                    price: 34600,
                                    addonCombinations: [
                                      {
                                        groupId: "68923972",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396733",
                                        variationId: "31066938",
                                      },
                                    ],
                                    price: 17300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923972",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841914",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841924",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841954",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923972",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841914",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841924",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841954",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 17300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.5",
                                  ratingCount: "25 ratings",
                                  ratingCountV2: "25",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Parottas",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378904",
                              name: "Dry Chapati",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "vujildtrdyn6temnxvio",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808604",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 30,
                                        default: 1,
                                        id: "77096149",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 30,
                                        id: "77096150",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808604",
                                        variationId: "77096149",
                                      },
                                    ],
                                    price: 3000,
                                    addonCombinations: [
                                      {
                                        groupId: "67834961",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808604",
                                        variationId: "77096150",
                                      },
                                    ],
                                    price: 3000,
                                    addonCombinations: [
                                      {
                                        groupId: "67834961",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67834961",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67834963",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67834964",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67834961",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67834963",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67834964",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "43 ratings",
                                  ratingCountV2: "43",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378807",
                              name: "Egg Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "fdreyhmkuy5uqcsp4yj4",
                              inStock: 1,
                              price: 9100,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67836005",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836018",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836025",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "17 ratings",
                                  ratingCountV2: "17",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378770",
                              name: "Ceylon Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "ediibdf3gsujqd6ifutq",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808666",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 37,
                                        default: 1,
                                        id: "77096273",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 37,
                                        id: "77096274",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808666",
                                        variationId: "77096273",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67838168",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808666",
                                        variationId: "77096274",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67838168",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838168",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838179",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838204",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67838168",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838179",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838204",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "414 ratings",
                                  ratingCountV2: "414",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378786",
                              name: "Ghee Chapathi",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "qsdkgx5pnq02wplrjoet",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808605",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 33,
                                        default: 1,
                                        id: "77096151",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 33,
                                        id: "77096152",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808605",
                                        variationId: "77096151",
                                      },
                                    ],
                                    price: 3300,
                                    addonCombinations: [
                                      {
                                        groupId: "67835038",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808605",
                                        variationId: "77096152",
                                      },
                                    ],
                                    price: 3300,
                                    addonCombinations: [
                                      {
                                        groupId: "67835038",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835038",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835045",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835050",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835038",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835045",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835050",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "19 ratings",
                                  ratingCountV2: "19",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378813",
                              name: "Aloo Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              inStock: 1,
                              isVeg: 1,
                              price: 9300,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67836752",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836783",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836748",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836792",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "30 ratings",
                                  ratingCountV2: "30",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378839",
                              name: "Empire Butter Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "alnvnbfhdf1zaw3js35b",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808683",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 46,
                                        default: 1,
                                        id: "77096300",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 46,
                                        id: "77096301",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808683",
                                        variationId: "77096300",
                                      },
                                    ],
                                    price: 4600,
                                    addonCombinations: [
                                      {
                                        groupId: "67841169",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808683",
                                        variationId: "77096301",
                                      },
                                    ],
                                    price: 4600,
                                    addonCombinations: [
                                      {
                                        groupId: "67841169",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841169",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841217",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841232",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67841169",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841217",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841232",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 4600,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "23 ratings",
                                  ratingCountV2: "23",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378854",
                              name: "Empire Mix Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "obn7o07vyyjjeqwifnsd",
                              inStock: 1,
                              price: 19000,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67841697",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841706",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841715",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.2",
                                  ratingCount: "5 ratings",
                                  ratingCountV2: "5",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378877",
                              name: "Empire Special Porota",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "bvctwoc4xuwlpwwe1bif",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808654",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 50,
                                        default: 1,
                                        id: "77096249",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 50,
                                        id: "77096250",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808654",
                                        variationId: "77096249",
                                      },
                                    ],
                                    price: 5000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837480",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808654",
                                        variationId: "77096250",
                                      },
                                    ],
                                    price: 5000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837480",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837480",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837488",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837496",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837480",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837488",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837496",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 5000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "114 ratings",
                                  ratingCountV2: "114",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378894",
                              name: "Malabar Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "ldyhzva56ancpx51vbcl",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808663",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 31,
                                        default: 1,
                                        id: "77096267",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 31,
                                        id: "77096268",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808663",
                                        variationId: "77096267",
                                      },
                                    ],
                                    price: 3100,
                                    addonCombinations: [
                                      {
                                        groupId: "67837736",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808663",
                                        variationId: "77096268",
                                      },
                                    ],
                                    price: 3100,
                                    addonCombinations: [
                                      {
                                        groupId: "67837736",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837736",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67837778",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837790",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837736",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837778",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837790",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3100,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "155 ratings",
                                  ratingCountV2: "155",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378901",
                              name: "Kerala Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "pwgx9hyprqwfn7nzbxew",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808607",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 37,
                                        default: 1,
                                        id: "77096155",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 37,
                                        id: "77096156",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808607",
                                        variationId: "77096155",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67835142",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808607",
                                        variationId: "77096156",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67835142",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835142",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835148",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835154",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835142",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835148",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835154",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "480 ratings",
                                  ratingCountV2: "480",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379011",
                              name: "Wheat Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "pcazjlp7ayhpfx6utrfs",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808674",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 37,
                                        default: 1,
                                        id: "77096289",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 37,
                                        id: "77096290",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808674",
                                        variationId: "77096289",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67840614",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808674",
                                        variationId: "77096290",
                                      },
                                    ],
                                    price: 3700,
                                    addonCombinations: [
                                      {
                                        groupId: "67840614",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67840614",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840633",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840655",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67840614",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840633",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840655",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "244 ratings",
                                  ratingCountV2: "244",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379042",
                              name: "Onion Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "myyuewcas7lpkmujnznr",
                              inStock: 1,
                              isVeg: 1,
                              price: 6100,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.2",
                                  ratingCount: "17 ratings",
                                  ratingCountV2: "17",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379053",
                              name: "Coin Parotta",
                              category: "Parottas",
                              description: "Serves 1",
                              imageId: "fmfupmuieyd62gfdcddh",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "23808667",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 30,
                                        default: 1,
                                        id: "77096275",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 30,
                                        id: "77096276",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "23808667",
                                        variationId: "77096275",
                                      },
                                    ],
                                    price: 3000,
                                    addonCombinations: [
                                      {
                                        groupId: "67838714",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "23808667",
                                        variationId: "77096276",
                                      },
                                    ],
                                    price: 3000,
                                    addonCombinations: [
                                      {
                                        groupId: "67838714",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838714",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838743",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838755",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67838714",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838743",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838755",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3000,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "664 ratings",
                                  ratingCountV2: "664",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Rotis",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379025",
                              name: "Romali Roti",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "otneezq4umvdhfq6jliz",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "24090467",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 32,
                                        default: 1,
                                        id: "77727570",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 32,
                                        id: "77727571",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "24090467",
                                        variationId: "77727570",
                                      },
                                    ],
                                    price: 3200,
                                    addonCombinations: [
                                      {
                                        groupId: "67841078",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "24090467",
                                        variationId: "77727571",
                                      },
                                    ],
                                    price: 3200,
                                    addonCombinations: [
                                      {
                                        groupId: "67841078",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841078",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841108",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841138",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67841078",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841108",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841138",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3200,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "477 ratings",
                                  ratingCountV2: "477",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378838",
                              name: "Butter Kulcha",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "thbj92jjhbmctknwaqnh",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "24090441",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 45,
                                        default: 1,
                                        id: "77727518",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 45,
                                        id: "77727519",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "24090441",
                                        variationId: "77727518",
                                      },
                                    ],
                                    price: 4500,
                                    addonCombinations: [
                                      {
                                        groupId: "67840688",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "24090441",
                                        variationId: "77727519",
                                      },
                                    ],
                                    price: 4500,
                                    addonCombinations: [
                                      {
                                        groupId: "67840688",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67840688",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67840702",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67840713",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67840688",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840702",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840713",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 4500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.6",
                                  ratingCount: "20 ratings",
                                  ratingCountV2: "20",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378856",
                              name: "Butter Naan",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "kpzbzfamrpnqune4nxn6",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "24090446",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 45,
                                        default: 1,
                                        id: "77727528",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 45,
                                        id: "77727529",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "24090446",
                                        variationId: "77727528",
                                      },
                                    ],
                                    price: 4500,
                                    addonCombinations: [
                                      {
                                        groupId: "67841762",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "24090446",
                                        variationId: "77727529",
                                      },
                                    ],
                                    price: 4500,
                                    addonCombinations: [
                                      {
                                        groupId: "67841762",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841762",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841772",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841779",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67841762",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841772",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841779",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 4500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "259 ratings",
                                  ratingCountV2: "259",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378863",
                              name: "Butter Roti",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "svkp2zkf11nyaz7w6vs1",
                              inStock: 1,
                              isVeg: 1,
                              price: 3600,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67837247",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837264",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837270",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.7",
                                  ratingCount: "56 ratings",
                                  ratingCountV2: "56",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378914",
                              name: "Kulcha",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "bzdjx3rqgccz6iuyyk0b",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "24090461",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 35,
                                        default: 1,
                                        id: "77727558",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 35,
                                        id: "77727559",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "24090461",
                                        variationId: "77727558",
                                      },
                                    ],
                                    price: 3500,
                                    addonCombinations: [
                                      {
                                        groupId: "67835381",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "24090461",
                                        variationId: "77727559",
                                      },
                                    ],
                                    price: 3500,
                                    addonCombinations: [
                                      {
                                        groupId: "67835381",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835381",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835404",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835410",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835381",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835404",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835410",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "2.8",
                                  ratingCount: "13 ratings",
                                  ratingCountV2: "13",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378934",
                              name: "Tandoori Naan",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "mq1wvypya46dahpmedir",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "24090462",
                                    name: "Complementary",
                                    variations: [
                                      {
                                        name: "Complementary Dal",
                                        price: 35,
                                        default: 1,
                                        id: "77727560",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Complementary Red Gravy",
                                        price: 35,
                                        id: "77727561",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "24090462",
                                        variationId: "77727560",
                                      },
                                    ],
                                    price: 3500,
                                    addonCombinations: [
                                      {
                                        groupId: "67836646",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "24090462",
                                        variationId: "77727561",
                                      },
                                    ],
                                    price: 3500,
                                    addonCombinations: [
                                      {
                                        groupId: "67836646",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836646",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67836657",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836664",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67836646",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836657",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836664",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 3500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "59 ratings",
                                  ratingCountV2: "59",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378947",
                              name: "Tandoori Roti",
                              category: "Rotis",
                              description: "Serves 1",
                              imageId: "w3yfetb1zmaa7yawvmbg",
                              inStock: 1,
                              isVeg: 1,
                              price: 3200,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839671",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839698",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839715",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.6",
                                  ratingCount: "119 ratings",
                                  ratingCountV2: "119",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "North Indian Veg (dry)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378779",
                              name: "Aloo Jeera",
                              category: "North Indian Veg (dry)",
                              description: "Serves 1",
                              imageId: "rpjyleakcpl5rs1e8zne",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396615",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 172,
                                        id: "31066637",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 101,
                                        default: 1,
                                        id: "31066636",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396615",
                                        variationId: "31066637",
                                      },
                                    ],
                                    price: 17200,
                                    addonCombinations: [
                                      {
                                        groupId: "67838095",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838095",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838095",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838108",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838108",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838108",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838099",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838099",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838099",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838093",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67838093",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396615",
                                        variationId: "31066636",
                                      },
                                    ],
                                    price: 10100,
                                    addonCombinations: [
                                      {
                                        groupId: "67838095",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838095",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838095",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838114",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838108",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67838108",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67838108",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67838128",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67838099",
                                        addonId: "54156499",
                                      },
                                      {
                                        groupId: "67838099",
                                        addonId: "54156500",
                                      },
                                      {
                                        groupId: "67838099",
                                        addonId: "54156501",
                                      },
                                      {
                                        groupId: "67838093",
                                        addonId: "19994970",
                                      },
                                      {
                                        groupId: "67838093",
                                        addonId: "54156498",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838140",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67838095",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838114",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838108",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838128",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838099",
                                  groupName: "Salad",
                                  choices: [
                                    {
                                      id: "54156499",
                                      name: "Pappad",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156500",
                                      name: "Mini Salad",
                                      price: 3000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156501",
                                      name: "Green Salad",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838093",
                                  groupName: "Curd",
                                  choices: [
                                    {
                                      id: "19994970",
                                      name: "Curd",
                                      price: 4000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156498",
                                      name: "Raitha",
                                      price: 3500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 2,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838140",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 10100,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "7 ratings",
                                  ratingCountV2: "7",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "North Indian Veg (gravy)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "79335786",
                              name: "Tawa Paneer",
                              category: "North Indian Veg (gravy)",
                              description: "Serves 1",
                              imageId: "nlfkcvfulkz0iionu9om",
                              inStock: 1,
                              isVeg: 1,
                              price: 20300,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839108",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839113",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839135",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.5",
                                  ratingCount: "9 ratings",
                                  ratingCountV2: "9",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "79335787",
                              name: "Tawa Veg",
                              category: "North Indian Veg (gravy)",
                              description: "Serves 1",
                              imageId: "k1bsgnny2kert0wxj8ab",
                              inStock: 1,
                              isVeg: 1,
                              price: 20300,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67841011",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841014",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841058",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "5 ratings",
                                  ratingCountV2: "5",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378865",
                              name: "Kadai Paneer",
                              category: "North Indian Veg (gravy)",
                              description: "Serves 1",
                              imageId: "bxxarwcannwouaouy3os",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396651",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 240,
                                        id: "31066731",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 133,
                                        default: 1,
                                        id: "31066730",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396651",
                                        variationId: "31066731",
                                      },
                                    ],
                                    price: 24000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923570",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837280",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837280",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837280",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396651",
                                        variationId: "31066730",
                                      },
                                    ],
                                    price: 13300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923570",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837280",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837280",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837280",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837286",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837304",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923570",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837280",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837286",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837304",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.3",
                                  ratingCount: "21 ratings",
                                  ratingCountV2: "21",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378876",
                              name: "Kadai Veg",
                              category: "North Indian Veg (gravy)",
                              description: "Serves 1",
                              imageId: "s3wwjyfhrp5fgcmajdjr",
                              inStock: 1,
                              isVeg: 1,
                              price: 13800,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "68923580",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837446",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837453",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837470",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.4",
                                  ratingCount: "9 ratings",
                                  ratingCountV2: "9",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378921",
                              name: "Paneer Tikka Masala",
                              category: "North Indian Veg (gravy)",
                              description: "Serves 1",
                              imageId: "tyrlfm9g8r18zdarvhsv",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396679",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 240,
                                        id: "31066802",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 133,
                                        default: 1,
                                        id: "31066801",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396679",
                                        variationId: "31066802",
                                      },
                                    ],
                                    price: 24000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923451",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67836039",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67836039",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67836039",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396679",
                                        variationId: "31066801",
                                      },
                                    ],
                                    price: 13300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923451",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67836039",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67836039",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67836039",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836046",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836057",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923451",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836039",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836046",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836057",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 13300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.5",
                                  ratingCount: "88 ratings",
                                  ratingCountV2: "88",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Desserts",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "83973552",
                              name: "Tender Coconut Pudding",
                              category: "Desserts",
                              description:
                                "Serves 1 | A refreshing delight with delicate flavors made from tender coconut, all set to melt in your mouth.(100gms)",
                              imageId: "kfjnsxwv02iiquqaykvc",
                              inStock: 1,
                              isVeg: 1,
                              price: 7100,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839754",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {
                                text: "Bestseller",
                                textColor: "#ffffff",
                                topBackgroundColor: "#d53d4c",
                                bottomBackgroundColor: "#b02331",
                              },
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              isBestseller: true,
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "314 ratings",
                                  ratingCountV2: "314",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Egg Item",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378767",
                              name: "Egg Burji",
                              category: "Egg Item",
                              description: "Serves 1",
                              imageId: "cg5s6kzlpaunkh8fplua",
                              inStock: 1,
                              price: 8200,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "7 ratings",
                                  ratingCountV2: "7",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378762",
                              name: "Egg Bulls Eye",
                              category: "Egg Item",
                              description: "Serves 1",
                              imageId: "fryhh8spfxlbiwtamjgp",
                              inStock: 1,
                              price: 5100,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.8",
                                  ratingCount: "4 ratings",
                                  ratingCountV2: "4",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379037",
                              name: "Spl Dal Fry With Egg",
                              category: "Egg Item",
                              description: "Serves 1",
                              imageId: "auuwpssammnfjaocpuam",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396735",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 258,
                                        id: "31066945",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 143,
                                        default: 1,
                                        id: "31066944",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396735",
                                        variationId: "31066945",
                                      },
                                    ],
                                    price: 25800,
                                    addonCombinations: [
                                      {
                                        groupId: "68923948",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841585",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841585",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841585",
                                        addonId: "54156400",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396735",
                                        variationId: "31066944",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923948",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841585",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841585",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841585",
                                        addonId: "54156400",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923948",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841585",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.6",
                                  ratingCount: "5 ratings",
                                  ratingCountV2: "5",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378799",
                              name: "Egg Omelette",
                              category: "Egg Item",
                              description: "Serves 1",
                              imageId: "mkh1a6yrfor6cqvr1tua",
                              inStock: 1,
                              price: 8200,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.6",
                                  ratingCount: "7 ratings",
                                  ratingCountV2: "7",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378789",
                              name: "Egg Masala",
                              category: "Egg Item",
                              description: "Serves 1",
                              imageId: "nfzt7u6m5oqvohhvejq7",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396621",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 224,
                                        id: "31066650",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 117,
                                        default: 1,
                                        id: "31066649",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396621",
                                        variationId: "31066650",
                                      },
                                    ],
                                    price: 22400,
                                    addonCombinations: [
                                      {
                                        groupId: "68923378",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835664",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835664",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835664",
                                        addonId: "54156400",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396621",
                                        variationId: "31066649",
                                      },
                                    ],
                                    price: 11700,
                                    addonCombinations: [
                                      {
                                        groupId: "68923378",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835664",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835664",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835664",
                                        addonId: "54156400",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923378",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835664",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 11700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "29 ratings",
                                  ratingCountV2: "29",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378809",
                              name: "Boiled Egg (2 Nos)",
                              category: "Egg Item",
                              description: "Serves 1",
                              imageId: "ruywk7pf1yvmzeb9jnv3",
                              inStock: 1,
                              price: 4100,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67836594",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.9",
                                  ratingCount: "8 ratings",
                                  ratingCountV2: "8",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Empire Special Tawa Dishes",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378895",
                              name: "Fiery Chicken Wings",
                              category: "Empire Special Tawa Dishes",
                              description: "Serves 1",
                              imageId: "pw9oczdrdjeeos8zxwn2",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396666",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066770",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 270,
                                        id: "31066769",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 145,
                                        default: 1,
                                        id: "31066771",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396666",
                                        variationId: "31066770",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837810",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837810",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837810",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396666",
                                        variationId: "31066769",
                                      },
                                    ],
                                    price: 27000,
                                    addonCombinations: [
                                      {
                                        groupId: "67837810",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837810",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837810",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396666",
                                        variationId: "31066771",
                                      },
                                    ],
                                    price: 14500,
                                    addonCombinations: [
                                      {
                                        groupId: "67837810",
                                        addonId: "54156513",
                                      },
                                      {
                                        groupId: "67837810",
                                        addonId: "54156514",
                                      },
                                      {
                                        groupId: "67837810",
                                        addonId: "54156515",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837813",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67837810",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837813",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14500,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "105 ratings",
                                  ratingCountV2: "105",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378939",
                              name: "Hot Chilly Prawns (tawa Fry)",
                              category: "Empire Special Tawa Dishes",
                              description: "Serves 1",
                              imageId: "w0ryzceyck4utca3l9pf",
                              inStock: 1,
                              price: 36200,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67836840",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836842",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.6",
                                  ratingCount: "8 ratings",
                                  ratingCountV2: "8",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379050",
                              name: "Fiery Basa Fish Fillet",
                              category: "Empire Special Tawa Dishes",
                              description: "Serves 1",
                              imageId: "cmbtminwmsomjsaxmad4",
                              inStock: 1,
                              price: 35500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67838479",
                                  groupName: "Dips",
                                  choices: [
                                    {
                                      id: "54156513",
                                      name: "Mayonnaise 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156514",
                                      name: "Grill Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156515",
                                      name: "Mint Chutney 50Ml",
                                      price: 2000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838485",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.4",
                                  ratingCount: "36 ratings",
                                  ratingCountV2: "36",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Fried Rice & Noodles",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379006",
                              name: "Prawns Noodles",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "u6dyi5smdbza2mzgayc4",
                              inStock: 1,
                              price: 24500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839299",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839317",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378994",
                              name: "Prawns Fried Rice",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "csop6sf3zuvis19txy7q",
                              inStock: 1,
                              price: 24500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67837044",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837054",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "19 ratings",
                                  ratingCountV2: "19",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378780",
                              name: "Egg Fried Rice",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "vretzm7ieccmpf28g4oh",
                              inStock: 1,
                              price: 19400,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67835129",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835137",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.9",
                                  ratingCount: "17 ratings",
                                  ratingCountV2: "17",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378796",
                              name: "Egg Noodles",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "xwj7vognggrordwiqgag",
                              inStock: 1,
                              price: 19400,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67835578",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835585",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.5",
                                  ratingCount: "10 ratings",
                                  ratingCountV2: "10",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378820",
                              name: "Chicken Fried Rice",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "pkoi9cfitwisdevisr7v",
                              inStock: 1,
                              price: 21400,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839356",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839383",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "53 ratings",
                                  ratingCountV2: "53",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378927",
                              name: "Chicken Noodles",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "bmwno4vupnvavpiylhii",
                              inStock: 1,
                              price: 21000,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67835952",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835961",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "14 ratings",
                                  ratingCountV2: "14",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378941",
                              name: "Mix Fried Rice Non Veg",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "nhyk8elhcalzcdybca75",
                              inStock: 1,
                              price: 23500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839252",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839275",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.6",
                                  ratingCount: "10 ratings",
                                  ratingCountV2: "10",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378989",
                              name: "Veg Noodles",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "hswbhovmezcxwmsxgap3",
                              inStock: 1,
                              isVeg: 1,
                              price: 18400,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67835840",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835864",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.6",
                                  ratingCount: "9 ratings",
                                  ratingCountV2: "9",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379004",
                              name: "Veg Fried Rice",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "vvpigujlw9jyfbtt2xxa",
                              inStock: 1,
                              isVeg: 1,
                              price: 18400,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67839180",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67839193",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "7 ratings",
                                  ratingCountV2: "7",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379038",
                              name: "Mutton Fried Rice",
                              category: "Fried Rice & Noodles",
                              description: "Serves 1",
                              imageId: "uocn0xa0orusb0hitvqw",
                              inStock: 1,
                              price: 24500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67841595",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841608",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Shawarma",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378966",
                              name: "Tawa Shawarma",
                              category: "Shawarma",
                              description: "Serves 1",
                              imageId: "lknei9tpqykfvudty8ax",
                              inStock: 1,
                              price: 16200,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "72802134",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "690 ratings",
                                  ratingCountV2: "690",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "North Indian Non Veg (gravy)",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378793",
                              name: "Hyderabadi Chicken Boneless Gravy",
                              category: "North Indian Non Veg (gravy)",
                              description: "Serves 1",
                              imageId: "vjzytwcf9qrj6mjqm0hh",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396625",
                                    name: "Portion",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 500,
                                        id: "31066661",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 265,
                                        id: "31066660",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 153,
                                        default: 1,
                                        id: "31066659",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396625",
                                        variationId: "31066661",
                                      },
                                    ],
                                    price: 50000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923346",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396625",
                                        variationId: "31066660",
                                      },
                                    ],
                                    price: 26500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923346",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396625",
                                        variationId: "31066659",
                                      },
                                    ],
                                    price: 15300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923346",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67835436",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67835440",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835455",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923346",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835436",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835440",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835455",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 15300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.1",
                                  ratingCount: "10 ratings",
                                  ratingCountV2: "10",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "69091194",
                              name: "Kadai Chicken Gravy",
                              category: "North Indian Non Veg (gravy)",
                              description: "Serves 1",
                              imageId: "sw1jachllyur2m1eew2c",
                              inStock: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9996773",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 490,
                                        id: "33010839",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 255,
                                        id: "33010840",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 143,
                                        default: 1,
                                        id: "33010841",
                                        inStock: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9996773",
                                        variationId: "33010839",
                                      },
                                    ],
                                    price: 49000,
                                    addonCombinations: [
                                      {
                                        groupId: "68923697",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9996773",
                                        variationId: "33010840",
                                      },
                                    ],
                                    price: 25500,
                                    addonCombinations: [
                                      {
                                        groupId: "68923697",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9996773",
                                        variationId: "33010841",
                                      },
                                    ],
                                    price: 14300,
                                    addonCombinations: [
                                      {
                                        groupId: "68923697",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838559",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838569",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838598",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923697",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838559",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838569",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838598",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 14300,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "13 ratings",
                                  ratingCountV2: "13",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Salads",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378870",
                              name: "Green Salad",
                              category: "Salads",
                              description: "Serves 1",
                              imageId: "un1hkz8tobnfonsmllh0",
                              inStock: 1,
                              isVeg: 1,
                              price: 6500,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "10 ratings",
                                  ratingCountV2: "10",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "117673916",
                              name: "Mini Salad",
                              category: "Salads",
                              inStock: 1,
                              isVeg: 1,
                              price: 3500,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Soup",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378810",
                              name: "Lemon Coriander Chicken Soup",
                              category: "Soup",
                              description: "Serves 1",
                              inStock: 1,
                              price: 9100,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67836678",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "NONVEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "6 ratings",
                                  ratingCountV2: "6",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Veg Dry",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378928",
                              name: "Pappad",
                              category: "Veg Dry",
                              inStock: 1,
                              isVeg: 1,
                              price: 2000,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.4",
                                  ratingCount: "7 ratings",
                                  ratingCountV2: "7",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378841",
                              name: "Gobi Manchurian",
                              category: "Veg Dry",
                              description: "Serves 1",
                              imageId: "rsis0nq4bvjiu2g7ncks",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396640",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 174,
                                        id: "31066701",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 97,
                                        default: 1,
                                        id: "31066700",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396640",
                                        variationId: "31066701",
                                      },
                                    ],
                                    price: 17400,
                                    addonCombinations: [
                                      {
                                        groupId: "67841302",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396640",
                                        variationId: "31066700",
                                      },
                                    ],
                                    price: 9700,
                                    addonCombinations: [
                                      {
                                        groupId: "67841302",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67841302",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841321",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67841302",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841321",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 9700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.2",
                                  ratingCount: "33 ratings",
                                  ratingCountV2: "33",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378909",
                              name: "Paneer Chilly Dry",
                              category: "Veg Dry",
                              description: "Serves 1",
                              imageId: "j1kkkv5uzgtbtgnwexkr",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396673",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 179,
                                        id: "31066787",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 102,
                                        default: 1,
                                        id: "31066786",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396673",
                                        variationId: "31066787",
                                      },
                                    ],
                                    price: 17900,
                                    addonCombinations: [
                                      {
                                        groupId: "67835078",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396673",
                                        variationId: "31066786",
                                      },
                                    ],
                                    price: 10200,
                                    addonCombinations: [
                                      {
                                        groupId: "67835078",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835078",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835082",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835078",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835082",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 10200,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.7",
                                  ratingCount: "108 ratings",
                                  ratingCountV2: "108",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378913",
                              name: "Paneer Tikka Dry",
                              category: "Veg Dry",
                              description: "Serves 1",
                              imageId: "bnnvweiswij9ms6srn5y",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396674",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 204,
                                        id: "31066789",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 112,
                                        default: 1,
                                        id: "31066788",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396674",
                                        variationId: "31066789",
                                      },
                                    ],
                                    price: 20400,
                                    addonCombinations: [
                                      {
                                        groupId: "67835311",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396674",
                                        variationId: "31066788",
                                      },
                                    ],
                                    price: 11200,
                                    addonCombinations: [
                                      {
                                        groupId: "67835311",
                                        addonId: "54156387",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156388",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156389",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156390",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156391",
                                      },
                                      {
                                        groupId: "67835311",
                                        addonId: "54156392",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67835324",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "67835311",
                                  groupName: "Gravy",
                                  choices: [
                                    {
                                      id: "54156389",
                                      name: "Kadai Chicken (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156392",
                                      name: "Kadai Veg",
                                      price: 13500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156388",
                                      name: "Pepper Chicken Masala (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156391",
                                      name: "Paneer Butter Masala (Qtr)",
                                      price: 11000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156387",
                                      name: "Butter Chicken Boneless (Qtr)",
                                      price: 15000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156390",
                                      name: "Mutton Varuval (Qtr)",
                                      price: 17000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67835324",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 11200,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.6",
                                  ratingCount: "48 ratings",
                                  ratingCountV2: "48",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Veg Gravy",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379016",
                              name: "Raitha",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "pxfpgtc5od4wnvqtqlgk",
                              inStock: 1,
                              isVeg: 1,
                              price: 3500,
                              variants: {},
                              variantsV2: {},
                              addons: [
                                {
                                  groupId: "67840242",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840255",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67840281",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378878",
                              name: "Dal Tadka",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396657",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Full",
                                        price: 237,
                                        id: "31066746",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Half",
                                        price: 128,
                                        id: "31066745",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 77,
                                        default: 1,
                                        id: "31066744",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396657",
                                        variationId: "31066746",
                                      },
                                    ],
                                    price: 23700,
                                    addonCombinations: [
                                      {
                                        groupId: "68923588",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396657",
                                        variationId: "31066745",
                                      },
                                    ],
                                    price: 12800,
                                    addonCombinations: [
                                      {
                                        groupId: "68923588",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396657",
                                        variationId: "31066744",
                                      },
                                    ],
                                    price: 7700,
                                    addonCombinations: [
                                      {
                                        groupId: "68923588",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837513",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837514",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837529",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923588",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837513",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837514",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837529",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 7700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.0",
                                  ratingCount: "90 ratings",
                                  ratingCountV2: "90",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378858",
                              name: "Dal Fry",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "jxxtujwekfghedg6axk1",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396648",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 122,
                                        id: "31066724",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 71,
                                        default: 1,
                                        id: "31066723",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396648",
                                        variationId: "31066724",
                                      },
                                    ],
                                    price: 12200,
                                    addonCombinations: [
                                      {
                                        groupId: "68923969",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396648",
                                        variationId: "31066723",
                                      },
                                    ],
                                    price: 7100,
                                    addonCombinations: [
                                      {
                                        groupId: "68923969",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67841796",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67841801",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67841842",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923969",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841796",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841801",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67841842",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 7100,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "473 ratings",
                                  ratingCountV2: "473",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378773",
                              name: "Aloo Gobi Gravy",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "oozehfnmdnbwmhtxyq9a",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396612",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 168,
                                        id: "31066629",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 97,
                                        default: 1,
                                        id: "31066628",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396612",
                                        variationId: "31066629",
                                      },
                                    ],
                                    price: 16800,
                                    addonCombinations: [
                                      {
                                        groupId: "68923688",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838433",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838433",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838433",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396612",
                                        variationId: "31066628",
                                      },
                                    ],
                                    price: 9700,
                                    addonCombinations: [
                                      {
                                        groupId: "68923688",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838433",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838433",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838433",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838437",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838463",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923688",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838433",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838437",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838463",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 9700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378784",
                              name: "Aloo Masala",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "evvmainq9mnv616adg3b",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396619",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 168,
                                        id: "31066644",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 97,
                                        default: 1,
                                        id: "31066643",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396619",
                                        variationId: "31066644",
                                      },
                                    ],
                                    price: 16800,
                                    addonCombinations: [
                                      {
                                        groupId: "68923264",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67834969",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67834969",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67834969",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396619",
                                        variationId: "31066643",
                                      },
                                    ],
                                    price: 9700,
                                    addonCombinations: [
                                      {
                                        groupId: "68923264",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67834969",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67834969",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67834969",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67834970",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67834974",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923264",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67834969",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67834970",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67834974",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 9700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378897",
                              name: "Paneer Butter Masala",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "aebk3ggsozr3gtijronv",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396667",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 189,
                                        id: "31066773",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 112,
                                        default: 1,
                                        id: "31066772",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396667",
                                        variationId: "31066773",
                                      },
                                    ],
                                    price: 18900,
                                    addonCombinations: [
                                      {
                                        groupId: "68923638",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396667",
                                        variationId: "31066772",
                                      },
                                    ],
                                    price: 11200,
                                    addonCombinations: [
                                      {
                                        groupId: "68923638",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67837921",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67837925",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67837971",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923638",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837921",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837925",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67837971",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 11200,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.8",
                                  ratingCount: "77 ratings",
                                  ratingCountV2: "77",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378983",
                              name: "Veg Kurma",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              imageId: "aabyscimg5b0yyf4k4yu",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396705",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 148,
                                        id: "31066868",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 87,
                                        default: 1,
                                        id: "31066867",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396705",
                                        variationId: "31066868",
                                      },
                                    ],
                                    price: 14800,
                                    addonCombinations: [
                                      {
                                        groupId: "68924642",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67836338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67836338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67836338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396705",
                                        variationId: "31066867",
                                      },
                                    ],
                                    price: 8700,
                                    addonCombinations: [
                                      {
                                        groupId: "68924642",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67836338",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67836338",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67836338",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67836349",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67836366",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68924642",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836338",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836349",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67836366",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 8700,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {},
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66379046",
                              name: "Palak Paneer",
                              category: "Veg Gravy",
                              description: "Serves 1",
                              inStock: 1,
                              isVeg: 1,
                              variants: {},
                              variantsV2: {
                                variantGroups: [
                                  {
                                    groupId: "9396738",
                                    name: "Portion ",
                                    variations: [
                                      {
                                        name: "Half",
                                        price: 189,
                                        id: "31066951",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                      {
                                        name: "Qtr",
                                        price: 112,
                                        default: 1,
                                        id: "31066950",
                                        inStock: 1,
                                        isVeg: 1,
                                        isEnabled: 1,
                                      },
                                    ],
                                  },
                                ],
                                pricingModels: [
                                  {
                                    variations: [
                                      {
                                        groupId: "9396738",
                                        variationId: "31066951",
                                      },
                                    ],
                                    price: 18900,
                                    addonCombinations: [
                                      {
                                        groupId: "68923707",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838805",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838805",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838805",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                  {
                                    variations: [
                                      {
                                        groupId: "9396738",
                                        variationId: "31066950",
                                      },
                                    ],
                                    price: 11200,
                                    addonCombinations: [
                                      {
                                        groupId: "68923707",
                                        addonId: "54935005",
                                      },
                                      {
                                        groupId: "67838805",
                                        addonId: "54156398",
                                      },
                                      {
                                        groupId: "67838805",
                                        addonId: "54156399",
                                      },
                                      {
                                        groupId: "67838805",
                                        addonId: "54156400",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156381",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156382",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156383",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156384",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156385",
                                      },
                                      {
                                        groupId: "67838810",
                                        addonId: "54156386",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156393",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156394",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156395",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156396",
                                      },
                                      {
                                        groupId: "67838849",
                                        addonId: "54156397",
                                      },
                                    ],
                                  },
                                ],
                              },
                              addons: [
                                {
                                  groupId: "68923707",
                                  groupName: "Rice",
                                  choices: [
                                    {
                                      id: "54935005",
                                      name: "Ghee Rice",
                                      price: 9900,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 1,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838805",
                                  groupName: "Parotta",
                                  choices: [
                                    {
                                      id: "54156398",
                                      name: "Coin Paroatta",
                                      price: 2700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156400",
                                      name: "Kerala Parotta",
                                      price: 3600,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156399",
                                      name: "Empire Spl Parotta",
                                      price: 4700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 3,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838810",
                                  groupName: "Starters",
                                  choices: [
                                    {
                                      id: "54156383",
                                      name: "Murg Tikka (Qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156382",
                                      name: "Chilly Chicken Boneless (qtr)",
                                      price: 14000,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156381",
                                      name: "Empire spl Chicken Kebab (qtr)",
                                      price: 10500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156385",
                                      name: "Paneer Chilly Dry",
                                      price: 10000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156384",
                                      name: "Pepper Mutton Dry (Qtr)",
                                      price: 17500,
                                      inStock: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156386",
                                      name: "Gobi Manchurian (Qtr)",
                                      price: 9500,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 6,
                                  maxFreeAddons: -1,
                                },
                                {
                                  groupId: "67838849",
                                  groupName: "Beverage / Fresh Juice",
                                  choices: [
                                    {
                                      id: "54156394",
                                      name: "Pepsi 750 ML",
                                      price: 3800,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156393",
                                      name: "Pepsi 250 ML",
                                      price: 1900,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156397",
                                      name: "Watermelon Juice",
                                      price: 7700,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156396",
                                      name: "Pulpy Grape Juice",
                                      price: 9000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                    {
                                      id: "54156395",
                                      name: "Lime Soda",
                                      price: 6000,
                                      inStock: 1,
                                      isVeg: 1,
                                      isEnabled: 1,
                                    },
                                  ],
                                  maxAddons: 5,
                                  maxFreeAddons: -1,
                                },
                              ],
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              defaultPrice: 11200,
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "3.5",
                                  ratingCount: "4 ratings",
                                  ratingCountV2: "4",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.NestedItemCategory",
                      title: "Juice Shop",
                      categories: [
                        {
                          title: "Lychee Fest",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "120688122",
                                  name: "Lychee Malba",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 14300,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "120688123",
                                  name: "Lychee Thickshake",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 13200,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "120688124",
                                  name: "Lychee Falooda",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 19800,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "120688125",
                                  name: "Lady Lychee",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 9900,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Falooda",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519917",
                                  name: "Empire Special Falooda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | This Signature Falooda is made with fresh cut pieces of Kiwi and Bananas mixed together with our special homemade rose milk mixture and a combination of jelly, poppy seeds, vermicelli, cereals and dry fruit mixture that is all topped off with rainbow ice cream and red cherry.",
                                  imageId: "ogahlaofpbpzwrcot0n6",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 19800,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.3",
                                      ratingCount: "25 ratings",
                                      ratingCountV2: "25",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519918",
                                  name: "Tender Coconut Falooda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | For those with a sweet tooth, the Tender Falooda Ice Cream is a delicious treat. This traditional dessert is made with tender coconut flavors combined with layers of jelly, poppy seeds, vermicelli, dry fruit mixture, and topped with red cherry.",
                                  imageId: "ccfzjuihtwly6azwyvyo",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 18700,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "3.4",
                                      ratingCount: "20 ratings",
                                      ratingCountV2: "20",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378851",
                                  name: "Bombay Falooda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | This Falooda is our interpretation of the classical Bombay style Falooda. Made from special homemade rose milk mix combined with layers of jelly, poppy seeds, vermicelli, dry fruit mixture, and topped with red cherry.",
                                  imageId: "r6rf3osd6glvxzj7p0d7",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 15400,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.7",
                                      ratingCount: "38 ratings",
                                      ratingCountV2: "38",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378935",
                                  name: "Kulfi Falooda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Treat yourself to a little indulgence with our Kulfi Ice Cream Falooda. Topped with Rich Malai Kulfi Ice Cream combined with layers of jelly, poppy seeds, vermicelli and dry fruit mixture, . It's the perfect end to any meal.",
                                  imageId: "ehwvo8dhazsxoslkfb9s",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 15400,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.4",
                                      ratingCount: "56 ratings",
                                      ratingCountV2: "56",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66379012",
                                  name: "Rabdi Falooda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Rabdi Falooda Ice Cream is an enticing and mouthwatering dessert. It has a delicious taste that is simply appetizing. This dessert is prepared using homemade rabadi, poppy seeds, vermicelli, ice cream, dry fruit mixture topped with red cherry.",
                                  imageId: "lbdoyqirtmggsxfag7z5",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 15400,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.3",
                                      ratingCount: "48 ratings",
                                      ratingCountV2: "48",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Chocolate Fest",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "104038660",
                                  name: "Chocolate Mousse",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "rkufmtlhzq2ufiuqs5on",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 7500,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "9 ratings",
                                      ratingCountV2: "9",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "104038661",
                                  name: "Hot Chocolate Fudge",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "l6sxj0dmltdp1yaoeaym",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 14500,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "8 ratings",
                                      ratingCountV2: "8",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378996",
                                  name: "Death By Chocolate",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | DBC is everyone’s favourite. Scoops of rich and creamy vanilla ice cream with of a warm homemade chocolate cake sponge, drizzled with our signature homemade DBC sauce and roasted peanuts is an absolute treat for every dessert lover.",
                                  imageId: "lvhxynldslndyn4u02ma",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 16500,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.6",
                                      ratingCount: "13 ratings",
                                      ratingCountV2: "13",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Desserts & Sundaes",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519935",
                                  name: "Mudpot",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Indulge your sweet tooth with this heavenly dessert. It's made of vanilla custard with bits of chocolate cake sponge, chocolate sauce, dry fruits and topped with rich and creamy scoop of vanilla ice cream.",
                                  imageId: "irvle0rfgkcsnjrzaykf",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 14300,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.3",
                                      ratingCount: "3 ratings",
                                      ratingCountV2: "3",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519936",
                                  name: "Red Velvet Pot",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | A melt in mouth Red Velvet Pot made with red velvet cake sponge, a layer of luscious fruit custard topped with scoop of strawberry ice cream.",
                                  imageId: "zwkm2liumpaxeo73unz6",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 14300,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519939",
                                  name: "Fruit Custard",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | A delightful change in your regular fruit salad. Made with the freshest seasonal fruits and crowned with layers vanilla and strawberry custard.",
                                  imageId: "x2nmqlzuckjqdvqpjjx2",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 10450,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "3.8",
                                      ratingCount: "6 ratings",
                                      ratingCountV2: "6",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378834",
                                  name: "Arabian Dry Fruit Sundae",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Enjoy this royal treat of sundae made from combination of chocolate, butterscotch and fig ice cream. it's topped with chocolate sauce, cereals and dry fruits.",
                                  imageId: "ruf8nsri8m5igsex3yvv",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 17600,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "3.7",
                                      ratingCount: "13 ratings",
                                      ratingCountV2: "13",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378871",
                                  name: "Banoffee Sundae",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Sweet and nutty, this banana sundae features our creamy butterscotch ice cream with caramelized powdered nuts, bananas, and a drizzle of honey.",
                                  imageId: "xyzo6bnmzc1aspobpmbo",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 15400,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.0",
                                      ratingCount: "4 ratings",
                                      ratingCountV2: "4",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378967",
                                  name: "Fruit Salad With Vanilla Ice Cream",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | You’ll love this Fruit Salad with Ice Cream! It is made with freshly cut tropical fruits that are then mixed with pure honey. To finish it off, we top it with a scoop of rich vanilla and strawberry ice cream. This sweet treat is the perfect way to complete your meal.",
                                  imageId: "jgrrpdkpqkg89mhzz9cy",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 12100,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.3",
                                      ratingCount: "27 ratings",
                                      ratingCountV2: "27",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519933",
                                  name: "Gudbud",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | A refreshing combination scoops of Vanilla, Strawberry and Mango Ice Creams. With every bite you will experience the flavor adventures of fresh fruit salad and dry fruits. Because our Gudbud isn't just a treat, it's an experience.",
                                  imageId: "awmh6lks9jjpdtdxcqfj",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 14300,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "30 ratings",
                                      ratingCountV2: "30",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Empire Special Thick Shakes",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519913",
                                  name: "Fig (Anjeer)Thick Shakes",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Boasting a delightfully creamy and luxurious texture, our Anjeer Thick shake is a treat for all your senses. You'll love the distinctive flavour of figs, ice cold milk, chocolate sauce, and velvety ice cream.",
                                  imageId: "qlwu3dhy80nvz5ny4nhr",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685408",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71453050",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 290,
                                            id: "71453051",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685408",
                                            variationId: "71453050",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685408",
                                            variationId: "71453051",
                                          },
                                        ],
                                        price: 29000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.3",
                                      ratingCount: "10 ratings",
                                      ratingCountV2: "10",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519916",
                                  name: "Chocolate Thick Shake",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Made with rich chocolate flavour ice cream and syrup, and blended with chilled milk and crushed ice cubes, this thick shake is a choco-lover’s dream.",
                                  imageId: "pf4dqma3zk99y0qbmkcf",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685334",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 88,
                                            default: 1,
                                            id: "71452901",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 255,
                                            id: "71452902",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685334",
                                            variationId: "71452901",
                                          },
                                        ],
                                        price: 8800,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685334",
                                            variationId: "71452902",
                                          },
                                        ],
                                        price: 25500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 8800,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.1",
                                      ratingCount: "16 ratings",
                                      ratingCountV2: "16",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "103547994",
                                  name: "Strawberry Thick Shake",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "c9cczsfbshesboawhpxa",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685376",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 88,
                                            default: 1,
                                            id: "71452984",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 255,
                                            id: "71452986",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685376",
                                            variationId: "71452984",
                                          },
                                        ],
                                        price: 8800,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685376",
                                            variationId: "71452986",
                                          },
                                        ],
                                        price: 25500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 8800,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.0",
                                      ratingCount: "4 ratings",
                                      ratingCountV2: "4",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "103548070",
                                  name: "Vanilla Thick Shake",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "ga66rsafo2nekfbppjam",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685404",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 88,
                                            default: 1,
                                            id: "71453044",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 255,
                                            id: "71453045",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685404",
                                            variationId: "71453044",
                                          },
                                        ],
                                        price: 8800,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685404",
                                            variationId: "71453045",
                                          },
                                        ],
                                        price: 25500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 8800,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.6",
                                      ratingCount: "3 ratings",
                                      ratingCountV2: "3",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378844",
                                  name: "Apple Thick Shake",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Sweet, simple, and seriously good. Fresh apple slices are blended with ice cream and crushed ice cubes before being topped with a drizzle of the 100% pure honey.",
                                  imageId: "mdbasvaq4y6enw7667wa",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685439",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71453115",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 290,
                                            id: "71453116",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685439",
                                            variationId: "71453115",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685439",
                                            variationId: "71453116",
                                          },
                                        ],
                                        price: 29000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.1",
                                      ratingCount: "3 ratings",
                                      ratingCountV2: "3",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378857",
                                  name: "Arabian Dry Fruit",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Our Arabian Dry Fruit Thick Shake is bursting with flavors. The smooth shake combines ice cream, mango pulp and crunchy dry fruits to make a truly indulgent drink. It’s topped off with chocolate syrup for the perfect combination of sweetness, fruitiness and creaminess.",
                                  imageId: "ujizbqb5tumbx26gmjaj",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685451",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 165,
                                            default: 1,
                                            id: "71453139",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 485,
                                            id: "71453140",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685451",
                                            variationId: "71453139",
                                          },
                                        ],
                                        price: 16500,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685451",
                                            variationId: "71453140",
                                          },
                                        ],
                                        price: 48500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 16500,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.5",
                                      ratingCount: "16 ratings",
                                      ratingCountV2: "16",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378907",
                                  name: "Dates Thick Shakes",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Lean back and enjoy the thick goodness of Dates Thick Shake which is blended to perfection using creamy ice cream and chilled milk.",
                                  imageId: "zjbrmgzznqzprv0eqxkf",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685322",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71452876",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 290,
                                            id: "71452877",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685322",
                                            variationId: "71452876",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685322",
                                            variationId: "71452877",
                                          },
                                        ],
                                        price: 29000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.1",
                                      ratingCount: "12 ratings",
                                      ratingCountV2: "12",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378912",
                                  name: "Mango Thick Shakes",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Relish the rich and luscious mango flavour with the delightful taste of ice cream blended with chilled milk and mango pulp.",
                                  imageId: "vcatjomn6wq2f7uc5bif",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685324",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 88,
                                            default: 1,
                                            id: "71452880",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 255,
                                            id: "71452881",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685324",
                                            variationId: "71452880",
                                          },
                                        ],
                                        price: 8800,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685324",
                                            variationId: "71452881",
                                          },
                                        ],
                                        price: 25500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 8800,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "27 ratings",
                                      ratingCountV2: "27",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378988",
                                  name: "Red Velvet Queen",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Our Red Velvet Queen Shake is a blissful marriage of rich, creamy ice cream, chunks of red velvet sponge and chilled milk topped with strawberry syrup, this thick shake will get your taste buds tingling!",
                                  imageId: "gzwmxysqktbtir27y7tl",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685330",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 126.5,
                                            default: 1,
                                            id: "71452892",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 370.5,
                                            id: "71452893",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685330",
                                            variationId: "71452892",
                                          },
                                        ],
                                        price: 12650,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685330",
                                            variationId: "71452893",
                                          },
                                        ],
                                        price: 37050,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 12650,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66379030",
                                  name: "Sharjah Thick Shakes",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Constituted by the best of three - banana, mocha and chocolate syrup - this thick shake will keep you refreshed all day long.",
                                  imageId: "qutjx0rbupksxw5m5txz",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685437",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71453111",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 290,
                                            id: "71453112",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685437",
                                            variationId: "71453111",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685437",
                                            variationId: "71453112",
                                          },
                                        ],
                                        price: 29000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.8",
                                      ratingCount: "7 ratings",
                                      ratingCountV2: "7",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519928",
                                  name: "Chocolate Splash",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Delicious, lush and satisfying Chocolate Splash is our specialty thick shake that is blended with rich creamy ice cream, chunks of chocolate cake sponge with cold milk, crushed ice cubes and topped off with chocolate syrup.",
                                  imageId: "yy7rt105kynal6krzj8u",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685323",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 121,
                                            default: 1,
                                            id: "71452878",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 355,
                                            id: "71452879",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685323",
                                            variationId: "71452878",
                                          },
                                        ],
                                        price: 12100,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685323",
                                            variationId: "71452879",
                                          },
                                        ],
                                        price: 35500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 12100,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519929",
                                  name: "Kit Kat Thick Shake",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Treat yourself to this rich, thick Kit Kat chocolate shake at Empire Juice Shop. Made with chilled milk and ice cream mixed with crushed Kit Kat and chocolate syrup, you really can't beat this combination!",
                                  imageId: "sl00qfxotvsasoajelyg",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685309",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 137.5,
                                            default: 1,
                                            id: "71452849",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 399.5,
                                            id: "71452850",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685309",
                                            variationId: "71452849",
                                          },
                                        ],
                                        price: 13750,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685309",
                                            variationId: "71452850",
                                          },
                                        ],
                                        price: 39950,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 13750,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519931",
                                  name: "Oreo Thick Shake",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Oreo lovers know the deal. Made with chunks of Oreo Biscuits and a layer of rich, creamy ice cream that is topped off with chocolate syrup, this treat is loved by all.",
                                  imageId: "s7we1y34hpfywovxabhy",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685332",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 137.5,
                                            default: 1,
                                            id: "71452895",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 398.5,
                                            id: "71452897",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685332",
                                            variationId: "71452895",
                                          },
                                        ],
                                        price: 13750,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685332",
                                            variationId: "71452897",
                                          },
                                        ],
                                        price: 39850,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 13750,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.1",
                                      ratingCount: "4 ratings",
                                      ratingCountV2: "4",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Cold Press Fresh Juices",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378832",
                                  name: "Lime Juice",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | 100% Pure Fresh Juices, No Water Added",
                                  imageId: "udoijcwgskygmkhlmtvi",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685432",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 55,
                                            default: 1,
                                            id: "71453100",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 155,
                                            id: "71453101",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685432",
                                            variationId: "71453100",
                                          },
                                        ],
                                        price: 5500,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685432",
                                            variationId: "71453101",
                                          },
                                        ],
                                        price: 15500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 5500,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "42 ratings",
                                      ratingCountV2: "42",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378991",
                                  name: "Pineapple Juice",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | 100% Pure Fresh Juices, No Water Added",
                                  imageId: "npo1tjeruli8uhjz9bzd",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685337",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 77,
                                            default: 1,
                                            id: "71452907",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 220,
                                            id: "71452908",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685337",
                                            variationId: "71452907",
                                          },
                                        ],
                                        price: 7700,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685337",
                                            variationId: "71452908",
                                          },
                                        ],
                                        price: 22000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 7700,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "15 ratings",
                                      ratingCountV2: "15",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66379005",
                                  name: "Musambi Juice",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | 100% Pure Fresh Juices, No Water Added",
                                  imageId: "omk7dilfhvfqcaekxj7q",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685422",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 88,
                                            default: 1,
                                            id: "71453080",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 255,
                                            id: "71453081",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685422",
                                            variationId: "71453080",
                                          },
                                        ],
                                        price: 8800,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685422",
                                            variationId: "71453081",
                                          },
                                        ],
                                        price: 25500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 8800,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.3",
                                      ratingCount: "7 ratings",
                                      ratingCountV2: "7",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66379007",
                                  name: "Watermelon Juice",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | 100% Pure Fresh Juices, No Water Added",
                                  imageId: "fvckyewcu6jqz8zzn1ft",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685423",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 77,
                                            default: 1,
                                            id: "71453082",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 211,
                                            id: "71453083",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685423",
                                            variationId: "71453082",
                                          },
                                        ],
                                        price: 7700,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685423",
                                            variationId: "71453083",
                                          },
                                        ],
                                        price: 21100,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 7700,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.3",
                                      ratingCount: "15 ratings",
                                      ratingCountV2: "15",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519906",
                                  name: "Pulpy Grape juice",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | 100% Pure Fresh Juices, No Water Added",
                                  imageId: "olvadzot3td2gpamufru",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685427",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71453090",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 267,
                                            id: "71453091",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685427",
                                            variationId: "71453090",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685427",
                                            variationId: "71453091",
                                          },
                                        ],
                                        price: 26700,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "26 ratings",
                                      ratingCountV2: "26",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Mojitos & Coolers",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519923",
                                  name: "Blueberry Mojito",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | A mojito with a wild berry twist, this drink is perfect for sharing to celebrate any occasion.",
                                  imageId: "0c41c915e0eea4d075fccdc3615565b5",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685341",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 104.5,
                                            default: 1,
                                            id: "71452915",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 305.5,
                                            id: "71452916",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685341",
                                            variationId: "71452915",
                                          },
                                        ],
                                        price: 10450,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685341",
                                            variationId: "71452916",
                                          },
                                        ],
                                        price: 30550,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 10450,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "3.7",
                                      ratingCount: "10 ratings",
                                      ratingCountV2: "10",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519924",
                                  name: "Green Apple Mojito",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Our Green Apple Mojito is a comforting drink made from muddling fresh lime and fresh mint leaves combined with green apple syrup and chilled soda pour over crushed ice cubes.",
                                  imageId: "ompoano0xaald9fikep5",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685436",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71453109",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 290,
                                            id: "71453110",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685436",
                                            variationId: "71453109",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685436",
                                            variationId: "71453110",
                                          },
                                        ],
                                        price: 29000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "5.0",
                                      ratingCount: "6 ratings",
                                      ratingCountV2: "6",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378797",
                                  name: "Blue Angel",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Sip on a bright Blue Angel, a fun drink made with lime juice, sugar syrup, ice slush, soda and Blue Curacao syrup",
                                  imageId: "pr3ptnuz1yfiy0lsxg32",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685325",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71452882",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 290,
                                            id: "71452883",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685325",
                                            variationId: "71452882",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685325",
                                            variationId: "71452883",
                                          },
                                        ],
                                        price: 29000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "3.2",
                                      ratingCount: "5 ratings",
                                      ratingCountV2: "5",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378802",
                                  name: "Blue Lime Soda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Lighten up your mood with our Blue lime Soda. Made with blue curac?ao syrup, lemonade and splash of chilled soda water.",
                                  imageId: "rkz3pwnxd3b3l7satpsa",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 8800,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "3.7",
                                      ratingCount: "21 ratings",
                                      ratingCountV2: "21",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378803",
                                  name: "Lassi Sweet",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "tnh0qj18zqdtpx5neqvc",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 7700,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.8",
                                      ratingCount: "15 ratings",
                                      ratingCountV2: "15",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378831",
                                  name: "Ginger Lime Soda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | Ginger-lime soda is infused with the flavour of real ginger and lime, and sweetened sugar syrup.",
                                  imageId: "hs1ym1gdojov0hqd3x5o",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685431",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 71.5,
                                            default: 1,
                                            id: "71453098",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 205,
                                            id: "71453099",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685431",
                                            variationId: "71453098",
                                          },
                                        ],
                                        price: 7150,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685431",
                                            variationId: "71453099",
                                          },
                                        ],
                                        price: 20500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 7150,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.9",
                                      ratingCount: "13 ratings",
                                      ratingCountV2: "13",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378843",
                                  name: "Lime Mint Cooler",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | This is a cool and refreshing blend of crushed ice cubes, lime juice, mint leaves, and chilled water that is blended together .",
                                  imageId: "evunfe79jfwckwn2zx2r",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685438",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 71.5,
                                            default: 1,
                                            id: "71453113",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 205.5,
                                            id: "71453114",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685438",
                                            variationId: "71453113",
                                          },
                                        ],
                                        price: 7150,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685438",
                                            variationId: "71453114",
                                          },
                                        ],
                                        price: 20550,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 7150,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.8",
                                      ratingCount: "12 ratings",
                                      ratingCountV2: "12",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378862",
                                  name: "Lime Soda",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | A refreshing citrus flavored soda made with fresh lime, cooled with chilled soda and mixed with a touch of sugar syrup.",
                                  imageId: "udoijcwgskygmkhlmtvi",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685350",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 66,
                                            default: 1,
                                            id: "71452933",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1ltr",
                                            price: 202,
                                            id: "71452934",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685350",
                                            variationId: "71452933",
                                          },
                                        ],
                                        price: 6600,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685350",
                                            variationId: "71452934",
                                          },
                                        ],
                                        price: 20200,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 6600,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.2",
                                      ratingCount: "26 ratings",
                                      ratingCountV2: "26",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378977",
                                  name: "Mojito Mint",
                                  category: "Juice Shop",
                                  description:
                                    "Serves 1 | A refreshing classical drink made with muddling lemon wedge and fresh mint leaves and combined with fresh lime juice, chilled soda and crushed ice",
                                  imageId: "ye7vp7dueayhccb55znv",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21685326",
                                        name: "Potion",
                                        variations: [
                                          {
                                            name: "300ml",
                                            price: 99,
                                            default: 1,
                                            id: "71452884",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "1Ltr",
                                            price: 290,
                                            id: "71452885",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21685326",
                                            variationId: "71452884",
                                          },
                                        ],
                                        price: 9900,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21685326",
                                            variationId: "71452885",
                                          },
                                        ],
                                        price: 29000,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  defaultPrice: 9900,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.4",
                                      ratingCount: "13 ratings",
                                      ratingCountV2: "13",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Kulfis",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "100221420",
                                  name: "Matka Kulfi",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "knpo2manobuiagemdkib",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 8900,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.8",
                                      ratingCount: "11 ratings",
                                      ratingCountV2: "11",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "101419046",
                                  name: "Badam Kulfi",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "zndmhkekkyowt6bbrzjy",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 7000,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "4.1",
                                      ratingCount: "7 ratings",
                                      ratingCountV2: "7",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "101419048",
                                  name: "Pista Kulfi",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "rngynunellqmkinhe2t4",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 7000,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "101419049",
                                  name: "Dry Fruits Kulfi",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "leumcfclqrcwoyregzzy",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 7000,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "101419051",
                                  name: "Black Current Kulfi",
                                  category: "Juice Shop",
                                  description: "Serves 1",
                                  imageId: "u5xlpe6jfhjuknzqjlps",
                                  inStock: 1,
                                  isVeg: 1,
                                  price: 7000,
                                  variants: {},
                                  variantsV2: {},
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                    portionSize: "Serves 1",
                                  },
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                        {
                          title: "Scoop Ice Creams",
                          itemCards: [
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519909",
                                  name: "Pista Ice Cream",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21698609",
                                        name: "Scoops",
                                        variations: [
                                          {
                                            name: "Single",
                                            price: 70,
                                            default: 1,
                                            id: "71482047",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "Double",
                                            price: 135,
                                            id: "71482048",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21698609",
                                            variationId: "71482047",
                                          },
                                        ],
                                        price: 7000,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21698609",
                                            variationId: "71482048",
                                          },
                                        ],
                                        price: 13500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  defaultPrice: 7000,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519910",
                                  name: "Fig & Honey",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21698608",
                                        name: "Scoops",
                                        variations: [
                                          {
                                            name: "Single",
                                            price: 70,
                                            default: 1,
                                            id: "71482045",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "Double",
                                            price: 135,
                                            id: "71482046",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21698608",
                                            variationId: "71482045",
                                          },
                                        ],
                                        price: 7000,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21698608",
                                            variationId: "71482046",
                                          },
                                        ],
                                        price: 13500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  defaultPrice: 7000,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519911",
                                  name: "Butterscotch Ice Cream",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21698607",
                                        name: "Scoops",
                                        variations: [
                                          {
                                            name: "Single",
                                            price: 70,
                                            default: 1,
                                            id: "71482043",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "Double",
                                            price: 135,
                                            id: "71482044",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21698607",
                                            variationId: "71482043",
                                          },
                                        ],
                                        price: 7000,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21698607",
                                            variationId: "71482044",
                                          },
                                        ],
                                        price: 13500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  defaultPrice: 7000,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "86519912",
                                  name: "Black Currant Ice Cream",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21698610",
                                        name: "Scoops",
                                        variations: [
                                          {
                                            name: "Single",
                                            price: 70,
                                            default: 1,
                                            id: "71482049",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "Double",
                                            price: 135,
                                            id: "71482050",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21698610",
                                            variationId: "71482049",
                                          },
                                        ],
                                        price: 7000,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21698610",
                                            variationId: "71482050",
                                          },
                                        ],
                                        price: 13500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  defaultPrice: 7000,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378874",
                                  name: "Chocolate Ice Cream",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "9396656",
                                        name: "Scoops",
                                        variations: [
                                          {
                                            name: "Single",
                                            price: 70,
                                            default: 1,
                                            id: "31066742",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "Double",
                                            price: 135,
                                            id: "31066743",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "9396656",
                                            variationId: "31066742",
                                          },
                                        ],
                                        price: 7000,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "9396656",
                                            variationId: "31066743",
                                          },
                                        ],
                                        price: 13500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  defaultPrice: 7000,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "5.0",
                                      ratingCount: "3 ratings",
                                      ratingCountV2: "3",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66378990",
                                  name: "Vanilla Ice Cream",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "21698606",
                                        name: "Scoops",
                                        variations: [
                                          {
                                            name: "Single",
                                            price: 70,
                                            default: 1,
                                            id: "71482041",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "Double",
                                            price: 135,
                                            id: "71482042",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "21698606",
                                            variationId: "71482041",
                                          },
                                        ],
                                        price: 7000,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "21698606",
                                            variationId: "71482042",
                                          },
                                        ],
                                        price: 13500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  defaultPrice: 7000,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {
                                      rating: "5.0",
                                      ratingCount: "3 ratings",
                                      ratingCountV2: "3",
                                    },
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                            {
                              card: {
                                "@type":
                                  "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                                info: {
                                  id: "66379040",
                                  name: "Strawberry Ice Cream",
                                  category: "Juice Shop",
                                  inStock: 1,
                                  isVeg: 1,
                                  variants: {},
                                  variantsV2: {
                                    variantGroups: [
                                      {
                                        groupId: "9396737",
                                        name: "Scoops",
                                        variations: [
                                          {
                                            name: "Single",
                                            price: 70,
                                            default: 1,
                                            id: "31066948",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                          {
                                            name: "Double",
                                            price: 135,
                                            id: "31066949",
                                            inStock: 1,
                                            isVeg: 1,
                                            isEnabled: 1,
                                          },
                                        ],
                                      },
                                    ],
                                    pricingModels: [
                                      {
                                        variations: [
                                          {
                                            groupId: "9396737",
                                            variationId: "31066948",
                                          },
                                        ],
                                        price: 7000,
                                      },
                                      {
                                        variations: [
                                          {
                                            groupId: "9396737",
                                            variationId: "31066949",
                                          },
                                        ],
                                        price: 13500,
                                      },
                                    ],
                                  },
                                  itemAttribute: {
                                    vegClassifier: "VEG",
                                  },
                                  defaultPrice: 7000,
                                  ribbon: {},
                                  type: "ITEM",
                                  itemBadge: {},
                                  badgesV2: {},
                                  ratings: {
                                    aggregatedRating: {},
                                  },
                                },
                                analytics: {},
                                hideRestaurantDetails: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory",
                      title: "Soft Drink",
                      itemCards: [
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "84001104",
                              name: "Pepsi (750ml)",
                              category: "Soft Drink",
                              description: "Serves 1",
                              imageId: "jabuzj9cy8kwmapilkwd",
                              inStock: 1,
                              isVeg: 1,
                              price: 3810,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.3",
                                  ratingCount: "25 ratings",
                                  ratingCountV2: "25",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                        {
                          card: {
                            "@type":
                              "type.googleapis.com/swiggy.presentation.food.v2.Dish",
                            info: {
                              id: "66378933",
                              name: "Mineral Water",
                              category: "Soft Drink",
                              description: "Serves 1",
                              imageId: "m7qdjyrd4xeafhfymibm",
                              inStock: 1,
                              isVeg: 1,
                              price: 2000,
                              variants: {},
                              variantsV2: {},
                              itemAttribute: {
                                vegClassifier: "VEG",
                                portionSize: "Serves 1",
                              },
                              ribbon: {},
                              type: "ITEM",
                              itemBadge: {},
                              badgesV2: {},
                              ratings: {
                                aggregatedRating: {
                                  rating: "4.5",
                                  ratingCount: "58 ratings",
                                  ratingCountV2: "58",
                                },
                              },
                            },
                            analytics: {},
                            hideRestaurantDetails: true,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.RestaurantLicenseInfo",
                      type: "FSSAI",
                      imageId: "fssai_final_edss9i",
                      text: ["License No. 11221333000291"],
                    },
                  },
                },
                {
                  card: {
                    card: {
                      "@type":
                        "type.googleapis.com/swiggy.presentation.food.v2.RestaurantAddress",
                      name: "Hotel Empire",
                      area: "Kothanur",
                      completeAddress:
                        "sy no 8/1, Ground Floor, Kothanur Village, Kr Puram Hobli, Bangalore , Kr Puram BBMP east- Karnataka 560077",
                    },
                  },
                },
              ],
            },
          },
        },
      },
    ],
    firstOffsetRequest: true,
    isQCLink: false,
  },
  tid: "61a095a6-e016-49b0-980e-94532ae54c9d",
  sid: "8ee62a84-ce7e-43aa-affe-21ae0792f928",
  deviceId: "4c76eb89-8b7e-d2b8-31f7-514fe5a48652",
  csrfToken: "jgsKHmvrM6LU-8JzOTeL5ueNl7eOiYLIMCyYYTR0",
};
